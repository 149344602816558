import React, {useEffect, useState} from 'react';
import RegisterForm from "../components/RegisterForm/RegisterForm";
import axiosInstance from "../axios";
import {CircularProgress} from "@mui/material";
import Spinner from "../components/Spinner";


function PageRegister() {
    const [sectors, setSectors] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true);
        axiosInstance
            .get(`user/sectors/`)
            .then((res) => {
                setSectors(res.data);
                setIsLoading(false);
            })
    }, [setSectors])

    return (
        <div className="page">
            {isLoading
                ? <Spinner />
                : <RegisterForm sectors={sectors} />
            }
        </div>
    );
}

export default PageRegister;