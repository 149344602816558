import React, {useState} from 'react';
import Typography from "@mui/material/Typography";
import {Alert, Button, TextField} from "@mui/material";
import {useForm} from "react-hook-form";
import axiosInstance from "../axios";
import {Link, useSearchParams} from 'react-router-dom';


function PageForgotPasswordReset(props) {
    const { register, handleSubmit} = useForm();
    const [alertMessage, setAlertMessage] = useState();
    const [alertSeverity, setAlertSeverity] = useState();
    const [ passwordResetSuccess, setPasswordResetSuccess ] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();

    const onSubmit = (data) => {
        const resetCode = searchParams.get('reset_code')
        const email = searchParams.get('email')
        axiosInstance
            .post('/forgot-password/reset', {...data, resetCode: resetCode, email: email})
            .then((res) => {
                setAlertSeverity('success')
                setAlertMessage('Mot de passe mis à jour avec succès. Vous pouvez maintenant vous connecter')
                setPasswordResetSuccess(true)
            })
            .catch((error) => {
                setAlertSeverity('error')
                setAlertMessage(error.response.data.message)
            })
    }

    return (
        <div className="page">
            <div style={{margin: '5rem auto 0 auto', maxWidth: '400px'}}>
                <div style={{width: "100%", textAlign: 'center'}}>
                    <Typography variant="h5">
                        Réinitialisation du mot de passe
                    </Typography>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '2rem'}}>
                        <TextField
                            {...register('password_1', {required: true})}
                            fullWidth
                            size="small"
                            type="password"
                            defaultValue=''
                            label='Mot de passe'
                        />
                    </div>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '2rem'}}>
                        <TextField
                            {...register('password_2', {required: true})}
                            fullWidth
                            size="small"
                            type="password"
                            defaultValue=''
                            label='Confirmation du mot de passe'
                        />
                    </div>

                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '2rem'}}>
                        <Button disabled={passwordResetSuccess} type="submit" variant="contained" className="login-form-button">
                            Envoyer
                        </Button>
                    </div>
                </form>

                {(alertSeverity && alertMessage) &&
                    <Alert severity={alertSeverity} className="alert">
                        {alertMessage}
                    </Alert>
                }
                {passwordResetSuccess &&
                    <div style={{marginTop: '2rem'}}>
                        <Link to='/login'>
                            <Button fullWidth type="submit" variant="contained" className="login-form-button">
                                Se connecter
                            </Button>
                        </Link>
                    </div>
                }
            </div>

        </div>
    );
}

export default PageForgotPasswordReset;