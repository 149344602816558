import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

import {BrowserRouter, Route, Routes} from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import {theme} from "./theme";

import App from './App';
import Admin from "./pages/Admin";
import Clubs from "./pages/Clubs";
import PageCreateTicket from "./pages/PageCreateTicket";
import PageHome from "./pages/PageHome";
import PageLogin from "./pages/PageLogin/PageLogin";
import PageMyTickets from "./pages/PageMyTickets";
import PageTicketDetail from "./pages/PageTicketDetail";
import PageRegister from "./pages/PageRegister";
import PageStats from "./pages/PageStats/PageStats";
import PageUserProfile from "./pages/PageUserProfile";
import PageStats2 from "./pages/PageStats/PageStats2";
import PageForgotPassword from "./pages/PageForgotPassword";
import PageForgotPasswordReset from "./pages/PageForgotPasswordReset";


// export const BASE_URL = 'http://127.0.0.1:8000'


const routing = (
    <BrowserRouter>
        <React.StrictMode>
            {/*<CSRFToken/>*/}
            <ThemeProvider theme={theme}>
                <Routes>
                    <Route exact path="/login" element={<PageLogin />} />
                    <Route exact path="/register" element={<PageRegister />} />
                    <Route exact path="/forgot-password" element={<PageForgotPassword />} />
                    <Route exact path="/forgot-password/reset" element={<PageForgotPasswordReset />} />

                    {/* Private routes */}
                    <Route element={<App />}>
                        <Route path="/" element={<PageHome />} />
                        <Route exact path="/ticket" element={<PageMyTickets />}/>
                        <Route exact path="/ticket/create" element={<PageCreateTicket />}/>
                        <Route exact path="/ticket/:id" element={<PageTicketDetail />}/>
                        <Route exact path="/administration" element={<Admin />}/>
                        <Route exact path="/profile" element={<PageUserProfile />}/>
                        <Route exact path="/clubs" element={<Clubs />}/>
                        <Route exact path="/statistics" element={<PageStats />}/>
                        {/*<Route exact path="/statistics" element={<PageStats2 />}/>*/}
                    </Route>
                </Routes>
            </ThemeProvider>
        </React.StrictMode>
    </BrowserRouter>
)
ReactDOM.render(
  routing,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
