import React, {useEffect, useState} from 'react';
import axiosInstance from "../axios";
import {useParams} from "react-router-dom";
import {Alert} from '@mui/material';
import TicketDetail from "./PageTicketDetail/TicketDetail/TicketDetail";
import TicketTimeline from "./PageTicketDetail/TicketTimeline/TicketTimeline";
import Spinner from "../components/Spinner";
import TicketStepForm from "./PageTicketDetail/TicketStepForm/TicketStepForm";
import {useCurrentUser} from "../components/AuthProvider";


function PageTicketDetail(props) {
    const { id } = useParams()
    const { currentUser } = useCurrentUser();
    const [ ticket, setTicket ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        axiosInstance
            .get(`ticket/${id}`)
            .then((res) => {
                setTicket(res.data);
                setIsLoading(false);
            })
    }, [id]);

    if (isLoading || !currentUser || ticket === null ) { return <Spinner/> }
    return (
        <div className="page mt-3">

            {currentUser.id === ticket.creator.id && ticket.closed && ticket.rating === 0 &&
                <Alert severity='warning' className="mb-1">
                    "Vous n'avez pas encore noter le ticket"
                </Alert>
            }

            <TicketDetail ticket={ticket} setTicket={setTicket}/>

            {!ticket.closed && (currentUser.id !== ticket.creator.id) &&
                <TicketStepForm ticket={ticket} setTicket={setTicket}/>
            }

            <TicketTimeline ticket={ticket}/>
        </div>

    );
}

export default PageTicketDetail;