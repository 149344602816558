import React, {useState} from 'react';
import './TicketDetail.css'
import {Card, Box, Button, TextField, Tooltip, Stack} from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import UserAvatar from "../../../components/UserAvatar";
import TicketRating from "../../../components/Ticket/TicketRating/TicketRating";
import {useForm} from "react-hook-form";
import {useCurrentUser} from "../../../components/AuthProvider";
import axiosInstance from "../../../axios";
import {Link} from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import IconButton from "@mui/material/IconButton";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {DatePicker} from "@mui/lab";


function TicketDetail(props) {
    const {ticket, setTicket} = props;
    const { register, handleSubmit} = useForm();
    const [editEnabled, setEditEnabled] = useState(false);
    const {currentUser} = useCurrentUser();
    const [deadline, setDeadline] = useState(ticket.deadline);

    const onTicketEdit = (data) => {
        const postData = {
            ...data,
            deadline: deadline
        }

        axiosInstance
            .patch(`ticket/${ticket.id}/`, postData)
            .then((res) => {
                setTicket(res.data)
                setEditEnabled(false)
            })
    }

    const handleDateChange = (newValue) => {
        const deadline = (!isNaN(newValue) && newValue !== null) ? newValue.toISOString() : null
        setDeadline(deadline)
    }

    return (
        <Card className="ticket-detail-card">
            {ticket.closed &&
                <div className="ticket-detail-closed-header">
                    <div className="ticket-detail-lock-info">
                        <LockIcon className="ticket-detail-closed-icon"/>
                        <h5 className="ticket-detail-closed-label">Ticket fermé</h5>
                    </div>
                    <TicketRating size="large" ticket={ticket} setTicket={setTicket} editable={currentUser.id === ticket.creator.id}/>
                </div>
            }
            <form>
                <div className="ticket-detail-container">
                    <div className="ticket-detail-left-container">
                        <h5 className="mb-05 pr-2">
                            {ticket.title}
                        </h5>
                        <div className="ticket-detail-description" >
                            <TextField
                                fullWidth
                                multiline
                                disabled={!editEnabled}
                                variant={editEnabled ? 'outlined' : 'standard'}
                                {...register('description')}
                                defaultValue={ticket.description}
                                sx={{
                                    border: "none",
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black"
                                    }
                                }}
                                InputProps={{
                                    sx: {
                                        '& textarea': {
                                            whiteSpace: 'pre-wrap', overflowWrap: "break-word"
                                        }
                                    },
                                    disableUnderline: !editEnabled
                                }}
                            />
                            {/*{ticket.description}*/}
                        </div>
                    </div>

                    <Box className="ticket-detail-right-container">
                        <div className="ticket-detail-right-inner p-2">
                            <h5 className="pb-1">Utilisateurs</h5>

                            <div className="ticket-detail-right-row">
                                <div className="ticket-detail-meta-label">
                                    Créateur
                                </div>

                                <div className="ticket-detail-user-avatar-container">
                                    <UserAvatar imageRelPath={ticket.creator.avatar}/>
                                    <div className="ticket-detail-user-name">
                                        {ticket.creator.name}
                                    </div>
                                </div>

                            </div>
                            <div className="ticket-detail-right-row">
                                <div className="ticket-detail-meta-label">
                                    Destinataire
                                </div>

                                <div className="ticket-detail-user-avatar-container">
                                    <UserAvatar imageRelPath={ticket.assignee.avatar}/>
                                    <div className="ticket-detail-user-name">
                                        {ticket.assignee.name}
                                    </div>
                                </div>
                            </div>


                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <h5 className="pb-1 mt-1 mr-1" style={{marginRight: '1em'}}>Client</h5>
                                <Tooltip title={"Copier le client dans un nouveau ticket"}>
                                    <Link to={`/ticket/create?copyTicketId=${ticket.id}`}>
                                        <IconButton sx={{border: '1px solid #EEE', alignSelf: 'center'}}>
                                            <AddIcon fontSize="small" className="rating-1"/>
                                        </IconButton>
                                    </Link>
                                </Tooltip>
                            </div>

                            <div className="ticket-detail-right-row">
                                <div className="ticket-detail-meta-label">
                                    Nom
                                </div>

                                <div className="font-s">
                                    <TextField
                                        fullWidth
                                        size="small"
                                        disabled={!editEnabled}
                                        variant={editEnabled ? 'outlined' : 'standard'}
                                        {...register('client_name')}
                                        defaultValue={ticket.client_name}
                                        sx={{
                                            border: "none",
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "black"
                                            }
                                        }}
                                        InputProps={{
                                            sx: {
                                                '& textarea': {
                                                    whiteSpace: 'pre-wrap', overflowWrap: "break-word"
                                                }
                                            },
                                            disableUnderline: !editEnabled
                                        }}
                                    />

                                    {/*{ticket.client_name}*/}
                                </div>
                            </div>
                            <div className="ticket-detail-right-row">
                                <div className="ticket-detail-meta-label">
                                    Entreprise
                                </div>

                                <div className="font-s">


                                    <TextField
                                        fullWidth
                                        size="small"
                                        disabled={!editEnabled}
                                        variant={editEnabled ? 'outlined' : 'standard'}
                                        {...register('client_business_name')}
                                        defaultValue={ticket.client_business_name}
                                        sx={{
                                            border: "none",
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "black"
                                            }
                                        }}
                                        InputProps={{
                                            sx: {
                                                '& textarea': {
                                                    whiteSpace: 'pre-wrap', overflowWrap: "break-word"
                                                }
                                            },
                                            disableUnderline: !editEnabled
                                        }}
                                    />
                                    {/*{ticket.client_business_name}*/}
                                </div>
                            </div>
                            <div className="ticket-detail-right-row">
                                <div className="ticket-detail-meta-label">
                                    Téléphone
                                </div>

                                <div className="font-s">


                                    <TextField
                                        fullWidth
                                        size="small"
                                        disabled={!editEnabled}
                                        variant={editEnabled ? 'outlined' : 'standard'}
                                        {...register('client_phone')}
                                        defaultValue={ticket.client_phone}
                                        sx={{
                                            border: "none",
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "black"
                                            }
                                        }}
                                        InputProps={{
                                            sx: {
                                                '& textarea': {
                                                    whiteSpace: 'pre-wrap', overflowWrap: "break-word"
                                                }
                                            },
                                            disableUnderline: !editEnabled
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="ticket-detail-right-row ">
                                <div className="ticket-detail-meta-label">
                                    Email
                                </div>
                                <div className="font-s">

                                    <TextField
                                        fullWidth
                                        size="small"
                                        disabled={!editEnabled}
                                        variant={editEnabled ? 'outlined' : 'standard'}
                                        {...register('client_email')}
                                        defaultValue={ticket.client_email}
                                        sx={{
                                            border: "none",
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "black"
                                            }
                                        }}
                                        InputProps={{
                                            sx: {
                                                '& textarea': {
                                                    whiteSpace: 'pre-wrap', overflowWrap: "break-word"
                                                }
                                            },
                                            disableUnderline: !editEnabled
                                        }}
                                    />
                                </div>

                            </div>
                            <h5 className="pb-1 mt-1">Date limite</h5>
                            <div style={{width: '100%'}}>

                                <div>

                                    <LocalizationProvider dateAdapter={AdapterDateFns} >
                                        <Stack spacing={1}
                                               className=""
                                               sx={{width: '100%'}}
                                        >
                                            <DatePicker
                                                disabled={!editEnabled}
                                                inputFormat="dd/MM/yyyy"
                                                value={deadline}
                                                onChange={handleDateChange}
                                                renderInput={(params) => (
                                                    <TextField
                                                        fullWidth
                                                        sx={{
                                                            border: "none",
                                                            "& .MuiInputBase-input.Mui-disabled": {
                                                                WebkitTextFillColor: "black"
                                                            }
                                                        }}
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            placeholder: ''
                                                        }}
                                                        size="small"
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </div>
                    </Box>
                </div>
            </form>

            {!ticket.closed &&
                <div style={{marginTop: "1rem", width: '100%', textAlign: 'right'}}>
                    {editEnabled
                        ?
                        <div>
                            <Button variant="contained" onClick={handleSubmit(onTicketEdit)} sx={{marginRight: '1rem'}}>
                                Sauvegarder
                            </Button>
                            <Button variant="outlined" onClick={() => {setEditEnabled(!editEnabled)}}>
                                Annuler
                            </Button>
                        </div>
                        :

                        <Button variant="contained" onClick={() => {setEditEnabled(!editEnabled)}}>
                            Modifier
                        </Button>
                    }
                </div>
            }
        </Card>
    );
}

export default TicketDetail;