import React from 'react';
import './TicketRating.css';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import Dialog from "@mui/material/Dialog";
import axiosInstance from "../../../axios";


function TicketRating(props) {
    const {ticket, setTicket, size, editable} = props;
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const handleDialogOpen = () => {
        editable && setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const onSubmitRating = (rating) => {
        if (!setTicket) {
            return
        }
        console.log(rating)

        axiosInstance
            .patch(`ticket/${ticket.id}/`, {rating: rating})
            .then((res) => {
                console.log(res.data);
                handleDialogClose();
                setTicket(res.data);
            })
    }

    return (
        <>
            <div onClick={handleDialogOpen} className="rating-container">
                <LocalActivityIcon fontSize={size} className={`rating-${ticket.rating}`}/>

            </div>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="ticket-detail-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="p-2">
                    <div id="ticket-detail-dialog-title" className="dialog-rating-title">
                        Noter la prestation
                    </div>

                    <div className="p-2 dialog-rating-container">
                        <div className="dialog-icon-container" onClick={() => onSubmitRating(3)}>
                            <LocalActivityIcon fontSize="large" className="rating-3"/>
                        </div>
                        <div className="dialog-icon-container" onClick={() => onSubmitRating(2)}>
                            <LocalActivityIcon fontSize="large" className="rating-2"/>
                        </div>
                        <div className="dialog-icon-container" onClick={() => onSubmitRating(1)}>
                            <LocalActivityIcon fontSize="large" className="rating-1"/>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
}

export default TicketRating;
