import React, {useEffect, useState} from 'react';
import './TicketCreate.css'
import axiosInstance from "../../../axios";
import {useForm} from "react-hook-form";
import {TextField, TextareaAutosize, MenuItem, Box, Card, Typography, Button, Stack, Select} from "@mui/material";
import {useNavigate} from "react-router-dom";
import UserAvatar from "../../UserAvatar";
import {useCurrentUser} from "../../AuthProvider";
import Spinner from "../../Spinner";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {DatePicker} from "@mui/lab";
import {useSearchParams} from "react-router-dom";


function PageCreateTicket(props) {
    const {currentUser} = useCurrentUser();
    const {register, handleSubmit, formState, setValue, getValues} = useForm();
    const { errors } = formState;
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [isLoading, setIsLoading] = useState(false)

    const [state, setState] = useState({
        clubList: [],
        clubId: null,
        members: [],
        deadline: null,
    })

    useEffect(() => {
        axiosInstance
            .get(`club`)
            .then((response) => { setState({...state, clubList: response.data}) })
            .catch((error) => console.error(error))
    }, [])

    useEffect(() => {
        const copyTicketId = searchParams.get('copyTicketId')
        if (copyTicketId) {
            axiosInstance
                .get(`ticket/${copyTicketId}`)
                .then((res) => {
                    const copyTicket = res.data;
                    setValue('client_name', copyTicket.client_name);
                    setValue('client_business_name', copyTicket.client_business_name);
                    setValue('client_phone', copyTicket.client_phone);
                    setValue('client_email', copyTicket.client_email);
                })
        }
    }, [])

    useEffect(() => {
        setIsLoading(true)
        if (state.clubList.length > 0) {
            const urlClubId = searchParams.get('clubId')
            const urlUserId = searchParams.get('userId')

            if (urlClubId && urlUserId) {
                setValue('assignee', urlUserId)
                setState({...state, clubId: urlClubId})
                setIsLoading(false)
            } else {
                setState({...state, clubId: currentUser.club.id})
                setIsLoading(false)
            }
        }
    }, [state.clubList]);

    useEffect(() => {
        if (state.clubId) {
            axiosInstance
                .get(`club/members/?club_id=${state.clubId}`)
                .then((res) => {
                    console.log('MEMBERS')
                    setState({...state, members: res.data});
                    // setMemberTextFieldEnabled(true)
                })
                .catch((error) => {
                    console.log('ERROR', error.response.status, error.response.statusText, error.response.statusMessage)
                })
        }
    }, [state.clubId])


    const fillForm = (userId) => {
        getUserProfile(userId)
            .then((response) => {
                setValue('client_name', response.data.name);
                setValue('client_business_name', response.data.business_name);
                setValue('client_phone', response.data.phone_number);
                setValue('client_email', response.data.email);
            })
            .catch((error) => console.error(error))
    }


    const getClubMembers = (clubId) => {
        axiosInstance
            .get(`club/members/?club_id=${clubId}`)
            .then((res) => {
                setState({...state, members: res.data});
                // setMemberTextFieldEnabled(true)
            })
            .catch((error) => {
                console.log('ERROR', error.response.status, error.response.statusText, error.response.statusMessage)
            })
    }


    const getUserProfile = (userId) => {
        return axiosInstance.get(`user/${userId}/`)
    }


    const handleDateChange = (newValue) => {
        const deadline = (!isNaN(newValue) && newValue !== null) ? newValue.toISOString() : null
        setState({...state, deadline: deadline})
    }

    const onSubmit = (data) => {
        console.log('DATA', data)
        data['client_phone'] = data['client_phone'].replace(/\s+/g, '')
        const data2 = {
            creator: currentUser.id,
            deadline: state.deadline,
            // assignee: assigneeId,
            ...data
        }
        console.log('data', data2)

        axiosInstance
            .post(`ticket/`, data2)
            .then((res) => {
                navigate(`/ticket/${res.data.id}`)
            })
            .catch((er) => console.error(er.message))
    }

    const onClubChange = (e) => {
        console.log('e.target.value', e.target.value)
        setState({...state, clubId: e.target.value})
    }

    if (isLoading) { return <Spinner/> }
    return (
        <div className="wrapper">
            <h2 className="page-title">CREER UN TICKET</h2>
            <Card
                sx={{
                    // width: '100%',
                    padding: '3em',
                    maxWidth: 'calc(1000px - 6em)',
                    margin: '0 auto'
                }}>
                <form className="ticket-create-form" onSubmit={handleSubmit(onSubmit)}>

                    <div className="form-row">
                        <h4 className="ticket-form-sub-title"> Destinataire </h4>
                    </div>

                    <div className="form-row mt-1">
                        <div className="input-container-half">
                            <TextField
                                select
                                size="small"
                                label="Sélectionner un club"
                                value={state.clubId}
                                variant="outlined"
                                error={!!errors?.name}
                                helperText={errors.member?.message}
                                className="form-input"
                                onChange={onClubChange}
                            >
                                {state.clubList.map((club) => (
                                    <MenuItem key={club.id} value={club.id}>
                                        {club.city}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>

                        <div className="input-container-half">
                            <Select
                                {...register('assignee', {required: true})}
                                size="small"
                                fullWidth
                                label="Sélectionner un destinataire"
                                defaultValue={getValues('assignee')}
                                variant="outlined"
                                className="form-input"
                            >
                                {state.members.map((member) => (
                                    member.id !== currentUser.id &&
                                        <MenuItem key={member.id} value={member.id}>
                                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                                <UserAvatar imageRelPath={member.avatar}/>
                                                <Typography sx={{marginLeft: '1rem'}}>{member.name}</Typography>
                                            </Box>
                                        </MenuItem>
                                ))}
                            </Select>
                            {errors.assignee && <p className="form-warning">{errors.assignee.message}</p>}
                        </div>

                    </div>


                    <div className="form-row mt-2">
                        <h4 className="ticket-form-sub-title"> Client </h4>
                        <Button
                            variant="outlined"
                            size="small"
                            color="secondary"
                            className="iamtheclient-btn"
                            onClick={() => fillForm(currentUser.id)}
                        >
                            Je suis le client
                        </Button>

                    </div>
                    <div className="form-row mt-1">
                        <div className="input-container-half">

                            <TextField
                                {...register('client_name', {required: 'Champ requis'})}
                                label="Nom du client"
                                size="small"
                                defaultValue=''
                                InputLabelProps={{ shrink: true }}
                                className="form-input"
                                error={!!errors?.title}
                            />
                            {errors.client_name && <p className="form-warning">{errors.client_name.message}</p>}
                        </div>
                        <div className="input-container-half">
                            <TextField
                                {...register('client_business_name', {required: 'Champ requis'})}
                                label="Nom de l'entreprise"
                                size="small"
                                defaultValue=''
                                InputLabelProps={{ shrink: true }}
                                className="form-input"
                                error={!!errors?.title}
                            />
                            {errors.client_business_name && <p className="form-warning">{errors.client_business_name.message}</p>}
                        </div>
                    </div>

                    <div className="form-row mt-1">
                        <div className="input-container-half">
                            <TextField
                                {...register('client_phone', {required: 'Champ requis', maxLength: 10})}
                                label="Téléphone"
                                size="small"
                                defaultValue=''
                                InputLabelProps={{ shrink: true }}
                                className="form-input"
                                error={!!errors?.title}
                            />
                            {errors.client_phone && <p className="form-warning">{errors.client_phone.message}</p>}
                            {errors.client_phone && errors.client_phone.type === "maxLength" && <p className="form-warning">10 chiffres maximum</p> }
                        </div>
                        <div className="input-container-half">
                            <TextField
                                {...register('client_email', {required: 'Champ requis', pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Adresse email invalide"
                                    }})}

                                label="Email"
                                size="small"
                                defaultValue=''
                                InputLabelProps={{ shrink: true }}
                                className="form-input"
                                error={!!errors?.title}
                            />
                            {errors.client_email && <p className="form-warning">{errors.client_email.message}</p>}
                        </div>
                    </div>

                    <div className="form-row mt-2">
                        <h4 className="ticket-form-sub-title"> Ticket </h4>
                    </div>

                    <div className="form-row mt-1">
                        <div className="input-container">
                            <TextField
                                {...register('title', {required: 'Champ requis'})}
                                label="Titre"
                                size="small"
                                fullWidth
                                defaultValue=''
                                className="form-input"
                                error={!!errors?.title}
                            />
                            {errors.title && <p className="form-warning">{errors.title.message}</p>}

                        </div>
                    </div>
                    <div className="form-row mt-1">

                        <LocalizationProvider dateAdapter={AdapterDateFns} >
                            <Stack spacing={1}
                                   className=""
                                sx={{width: '100%'}}
                            >
                                <DatePicker
                                    // label="Date desktop"
                                    inputFormat="dd/MM/yyyy"
                                    value={state.deadline}
                                    onChange={handleDateChange}
                                    label={"Date limite"}
                                    renderInput={(params) => <TextField {...params} size="small"/>}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </div>
                    <div className="form-row mt-1">
                        <TextareaAutosize
                            {...register('description')}
                            minRows={8}
                            placeholder="Ajouter une description"
                            className="form-text-area"
                        />
                    </div>
                    <div className="form-row row-center mt-1 ">

                        <Button
                            type="submit"
                            variant="contained"
                            className="ticket-create-submit-btn"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Créer le ticket
                        </Button>
                    </div>
                </form>
            </Card>
        </div>
    );
}

export default PageCreateTicket;