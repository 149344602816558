import React, {useState} from 'react';
import {Alert, Box, Button, TextField, Typography} from "@mui/material";
import './LoginForm.css'
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import axiosInstance from "../../../axios";


function LoginForm(props) {
    const { register, handleSubmit} = useForm();
    const navigate = useNavigate();
    const [alertMessage, setAlertMessage] = useState();
    const [alertSeverity, setAlertSeverity] = useState();

    const setAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
    }

    const onSubmit = (data) => {
        axiosInstance
            .post(`token/`, data)
            .then((res) => {
                localStorage.setItem('access_token', res.data.access);
                axiosInstance.defaults.headers.common.Authorization =
                    'JWT ' + localStorage.getItem('access_token');
                navigate('/')
            })
            .catch((err) => {
                err.response.status === 401 &&
                    setAlert('error', 'Identifiant ou mot de passe incorrect')
            })
    };

    return (
        <>
            <Box className="login-form-container">
                <Box className="login-form-box">
                    <Box className="login-form-header-row">
                        <img src={require('../../../assets/images/logo-full.jpg')} alt="logo" className="logo-login" />
                    </Box>
                    <Box className="login-form-header-row">
                        <Typography variant="h5">
                            Connexion
                        </Typography>
                    </Box>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Typography variant="body1" className="login-form-label">
                            Email
                        </Typography>
                        <TextField
                            {...register('email', {required: true})}
                            size="small"
                            defaultValue=''
                            className="login-form-input-container"
                        />
                        <Typography variant="body1" className="login-form-label">
                            Mot de passe
                        </Typography>
                        <TextField
                            {...register('password', {required: true})}
                            type="password"
                            size="small"
                            defaultValue=''
                            className="login-form-input-container"
                        />
                        <Box className="login-form-button-container">
                            <Button type="submit" variant="contained" className="login-form-button">
                                Envoyer
                            </Button>
                        </Box>
                    </form>
                </Box>

                {(alertSeverity && alertMessage) &&
                    <Alert severity={alertSeverity} className="alert">
                        {alertMessage}
                    </Alert>
                }
            </Box>
        </>
    );
}

export default LoginForm;