import React, {useState} from 'react';
import './RegisterForm.css';
import {useForm} from "react-hook-form";
import {useLocation, useSearchParams} from "react-router-dom";
import DefaultPortrait from "../../assets/images/Portrait_Placeholder.png";
import axiosInstance from "../../axios";
import {
    Alert,
    Avatar,
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Input,
    InputLabel,
    MenuItem,
    TextareaAutosize,
    TextField,
} from "@mui/material";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function RegisterForm(props) {
    const {sectors} = props;
    const { register, handleSubmit, setValue, formState: { errors }, setError, clearErrors} = useForm();
    const [searchParams, setSearchParams] = useSearchParams();
    const query = useQuery();

    const [avatarPreview, setAvatarPreview] = useState(DefaultPortrait)
    const [avatar, setAvatar] = useState(null)
    const [logoPreview, setLogoPreview] = useState(DefaultPortrait)
    const [logo, setLogo] = useState(null)

    const [alertMessage, setAlertMessage] = useState();
    const [alertSeverity, setAlertSeverity] = useState();

    const [disabled, setDisabled] = useState(false);

    const setAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
    }

    const onAvatarChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setAvatarPreview(URL.createObjectURL(event.target.files[0]));
            setAvatar(event.target.files[0])
            setValue('avatar', event.target.files[0])
            clearErrors()
        }
    }
    const onLogoChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setLogoPreview(URL.createObjectURL(event.target.files[0]));
            setLogo(event.target.files[0])
            setValue('business_logo', event.target.files[0])
            clearErrors()
        }
    }

    const onSubmit = (data) => {
        console.log("On Submit")
        if (!avatar || !logo) {
            !avatar && setError('avatar', {message: 'Une image avatar est requise'})
            !logo && setError('business_logo', {message: 'Une image logo est requise'})
            return
        }

        const inviteCode = query.get('invite_code');
        let formData = new FormData();

        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        })

        formData.append('avatar', avatar, avatar.name);
        formData.append('business_logo', logo, logo.name);
        formData.append('invite_code', inviteCode);

        for (const key of formData.entries()) {
            console.log(key[0] + ', ' + key[1]);
        }

        axiosInstance
            .post(`user/register/`, formData, { headers: {'Content-Type': 'multipart/form-data'} } )
            .then(() => {
                setAlert(
                    'success',
                    "Votre inscription doit maintenant être validée par un administrateur. Vous recevrez un email confirmant l'activation de votre compte."
                    )
                setDisabled(true)
            })
            .catch(() => {
                setAlert(
                    'error',
                    'Une erreur est survenue. Veuillez contacter un administrateur')

                // setPostData(false)
            })
    }

    const onErrors = (arg) => {
        console.log(arg)
    }


    return (
        <div className="wrapper">
            <Box className="register-form-logo-container">
                <img src={require('../../assets/images/logo-full.jpg')} alt="logo" className="register-form-logo"/>
            </Box>
            <h3 className="register-form-title">
                Inscription
            </h3>

            <form>
                <h5 className="mt-2">
                    Informations utilisateur
                </h5>
                <div className="register-form-group">
                    <div className="register-form-group-inputs">

                        <div className="mt-1">
                            <TextField
                                {...register('email', {required: 'Champ requis'})}
                                label="Email"
                                size="small"
                                defaultValue={searchParams.get('email')}
                                className="form-input"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#CCCCCC',
                                        },
                                    },
                                }}
                                inputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                            />
                            {errors.email && <p className="form-warning">{errors.email.message}</p>}
                        </div>

                        <div className="mt-1">
                            <TextField
                                {...register('name', {required: 'Champ requis'})}
                                label="Nom complet"
                                size="small"
                                defaultValue=''
                                className="form-input"
                            />
                            {errors.name && <p className="form-warning">{errors.name.message}</p>}
                        </div>

                        <div className="mt-1">
                            <TextField
                                {...register('phone_number', {required: true, minLength: 10, maxLength: 10})}
                                label="Téléphone"
                                size="small"
                                defaultValue=''
                                className="form-input"
                            />
                            {errors.phone_number && errors.phone_number.type === "required" && <p className="form-warning">Champ requis</p>}
                            {errors.phone_number && (errors.phone_number.type === "maxLength" || errors.phone_number.type === "minLength") &&
                                <p className="form-warning">Le numéro doit avoir 10 chiffres</p>
                            }
                        </div>

                        <div className="mt-1">
                            <TextField
                                {...register('password1',
                                    {required: 'Champ requis', minLength: 8})
                                }
                                label="Mot de passe"
                                type="password"
                                size="small"
                                defaultValue=''
                                className="form-input"

                            />
                            {errors.password1 && <p className="form-warning">{errors.password1.message}</p>}
                            {errors.password1 && errors.password1.type === "minLength" && <p className="form-warning">8 caractères minimum</p> }
                        </div>

                        <div className="mt-1">
                            <TextField
                                {...register('password2',
                                    {required: 'Champ requis', minLength: 8})
                                }
                                label="Confirmation du mot de passe"
                                type="password"
                                size="small"
                                defaultValue=''
                                className="form-input"

                            />
                            {errors.password2 && <p className="form-warning">{errors.password2.message}</p>}
                        </div>

                        <FormGroup className="mt-1">
                            <FormControlLabel
                                control={<Checkbox {...register('self_employed')} />}
                                label="Travailleur non salarié" />
                        </FormGroup>
                    </div>
                    <Box className="register-form-avatar-container">
                        <Avatar src={avatarPreview} sx={{height: 65, width: 65}} alt=""/>
                        <Box className="register-form-upload-btn-container">
                            <InputLabel htmlFor="avatar" className="upload-file-btn">
                                Ajouter une image de profil
                                <Input
                                    id="avatar"
                                    type="file"
                                    sx={{display: 'none'}}
                                    onChange={onAvatarChange}
                                />
                            </InputLabel>
                        </Box>
                        {errors.avatar && <p className="form-warning">{errors.avatar.message}</p>}
                    </Box>
                </div>

                <h5 className="mt-2">
                    Entreprise représentée
                </h5>
                <div className="register-form-group">
                    <Box className="register-form-group-inputs">

                        <div className="mt-1">
                            <TextField
                                {...register('business_name', {required: 'Champ requis'})}
                                label="Raison sociale"
                                size="small"
                                defaultValue=''
                                className="form-input"
                            />
                            {errors.business_name && <p className="form-warning">{errors.business_name.message}</p>}
                        </div>

                        <div className="mt-1">
                            <TextField
                                {...register('business_address', {required: 'Champ requis'})}
                                label="Adresse"
                                size="small"
                                defaultValue=''
                                className="form-input"
                            />
                            {errors.business_address && <p className="form-warning">{errors.business_address.message}</p>}
                        </div>

                        <div className="mt-1">
                            <TextField
                                select
                                {...register('business_sector', {required: 'Champ requis'})}
                                label="Secteur d'activité"
                                size="small"
                                defaultValue=''
                                className="form-input"
                            >
                                {sectors.map((sector) => (
                                    <MenuItem key={sector.id} value={sector.id}>
                                        {sector.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {errors.business_sector && <p className="form-warning">{errors.business_sector.message}</p>}
                        </div>

                        <div className="mt-1">
                            <TextareaAutosize
                                {...register('business_description')}
                                minRows={8}
                                placeholder="Description des services fournis"
                                className="form-text-area"
                            />
                        </div>
                    </Box>

                    <Box className="register-form-avatar-container">
                        <Avatar src={logoPreview} sx={{height: 65, width: 65}} alt=""/>
                        <Box className="register-form-upload-btn-container">
                            <InputLabel htmlFor="business_logo" className="upload-file-btn">
                                Ajouter le logo de l'entreprise
                                <Input
                                    id="business_logo"
                                    type="file"
                                    sx={{display: 'none'}}
                                    onChange={onLogoChange}
                                />
                            </InputLabel>
                        </Box>
                        {errors.business_logo && <p className="form-warning">{errors.business_logo.message}</p>}
                    </Box>
                </div>

                {(alertSeverity && alertMessage) &&
                    <Alert severity={alertSeverity} className="alert">
                        {alertMessage}
                    </Alert>
                }

                <div className="mt-2 pb-2">
                    <Button variant="contained" className="register-form-submit-btn" onClick={handleSubmit(onSubmit, onErrors)} disabled={disabled}>
                        Envoyer
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default RegisterForm;