import React from 'react';
import {PieChart, Pie, Cell, ResponsiveContainer, Legend} from 'recharts';
import LocalActivityIcon from "@mui/icons-material/LocalActivity";


const COLORS = ['#e65c27', '#FDCC0D', '#17a382', '#17a382'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        percent > 0 ?
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
            : ''
    );
};


function TicketRatingPie(props) {
    const { data } = props
    // console.log('pie', data)

    const test = (d) => {
        if (d.name === "1 étoile") {
            return (
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{marginRight: '.5rem'}}>{d.value}</div>

                    <LocalActivityIcon className="rating-1"/>
                </div>
            )
        } else if (d.name === "2 étoiles") {

            return (
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{marginRight: '.5rem'}}>{d.value}</div>
                    <LocalActivityIcon className="rating-2"/>
                </div>
            )
        } else if (d.name === "3 étoiles") {
            return (
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{marginRight: '.5rem'}}>{d.value}</div>
                    <LocalActivityIcon className="rating-3"/>
                </div>
            )
        }
    }

    const customLegend = () => {
        return (
            <div className="custom-legend">
                <div style={{display: 'flex', justifyContent: 'center'}}>
                {data.map((d, i) => (
                    <React.Fragment key={i}>

                        <div style={{margin: '1rem'}}>{test(d)}</div>
                    </React.Fragment>
                ))}
                </div>
            </div>
        )
    }

    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart width={300} height={300}>
                <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Legend content={customLegend}/>
                {/*<Tooltip/>*/}
            </PieChart>
        </ResponsiveContainer>
    );
}

export default TicketRatingPie;
