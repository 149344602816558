import React from 'react';
import LoginForm from "./LoginForm/LoginForm";
import {Link} from "react-router-dom";

/**
 *
 * @returns {JSX.Element}
 */
function PageLogin() {

    return (
        <div className="page">
            <LoginForm />
            <Link to='/forgot-password'>
                <div style={{color: '#DD2D42', fontSize: ".8rem", width: '100%', textAlign: 'center', marginTop: '2rem'}}>Mot de passe oublié ?</div>
            </Link>
        </div>
    );
}


export default PageLogin;
