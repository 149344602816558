import React, {useState} from 'react';
import axiosInstance from "../../axios";

function TicketRatingStars(props) {
    const { ticket, disabled, size } = props

    const [rating, setRating] = useState(ticket.rating);
    const [hover, setHover] = useState(0);

    const onRatingChange = (rating) => {
        setRating(rating)
        axiosInstance
            .patch(`ticket/${ticket.id}/`, {rating: rating})
            .then((res) => console.log(res.data))
    }
    return (
        <div className="star-rating">
            {[...Array(3)].map((star, index) => {
                index += 1;
                return (
                    !disabled
                        ?
                        <button
                            type="button"
                            key={index}
                            className={index <= (hover || rating) ? "on" : "off"}
                            onClick={() => onRatingChange(index)}
                            onMouseEnter={() => setHover(index)}
                            onMouseLeave={() => setHover(rating)}
                        >
                            <span className={`star-${size || 'medium'}`}>&#9733;</span>
                        </button>
                        :

                        <button
                            type="button"
                            key={index}
                            className={index <= (hover || rating) ? "on" : "off"}
                        >
                            <span className={`star-${size || 'medium'}`}>&#9733;</span>
                        </button>
                );
            })}
        </div>
    );
}

export default TicketRatingStars;