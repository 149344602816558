import React from 'react';
import './StatsKeyNumbers.css';
import {Box, Card} from "@mui/material";
import {ConfirmationNumberOutlined} from "@mui/icons-material";


function StatsKeyNumbers(props) {
    const { stats, ticketLabel, turnoverLabel } = props;

    return (
        <Card className="card stats-key-number-card">
            <Box className="stats-key-number-container">
                <Box className="wrapper stats-key-number-wrapper">
                    <div className="stats-key-number-value">
                        { stats.count ? stats.count : 0} <ConfirmationNumberOutlined/>
                    </div>
                    <div className="stats-key-number-label">
                        {ticketLabel}
                    </div>
                </Box>
            </Box>

            <Box className="stats-key-number-container">
                <Box className="wrapper stats-key-number-wrapper">
                    <div className="stats-key-number-value">
                        { stats.turnover
                            ?
                            stats.turnover.total.toString().replace('.', ',')
                            :
                            0
                        } €
                    </div>
                    <div className="stats-key-number-label">
                        {turnoverLabel}
                    </div>
                </Box>
            </Box>

        </Card>
    );
}

export default StatsKeyNumbers;