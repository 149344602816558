import React, {useEffect, useState} from 'react';
import axiosInstance from "../axios";
import AdminFlexTable from "../components/AdminFlexTable";
import {Box, Card} from "@mui/material";
import AdminUserInvitation from "../components/AdminUserInvitation";
import AdminInvitedUsers from "../components/AdminInvitedUsers";
import Spinner from "../components/Spinner";

function Admin(props) {
    const [users, setUsers] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [invitedUsers, setInvitedUsers] = useState([]);

    useEffect(() => {
        const promises = [
            axiosInstance
                .get('invitation/')
                .then((res) => {
                    setInvitedUsers(res.data);
                    console.log(res.data)
                }),
            axiosInstance
                .get(`user/all/`)
                .then((res) => {
                    setUsers(res.data);
                })
        ]

        Promise.all(promises)
            .then(() => setIsLoading(false))


    }, []);

    const onInvitationSent = (newUser) => {
        setInvitedUsers([...invitedUsers, newUser])
    }

    if (isLoading) {
        return <Spinner />
    }
    return (
        <Box className="page">

            <div className="page-wrapper">

                <h2 className="page-title">ADMINISTRATION</h2>
                <Box sx={{marginBottom: '3rem'}}>
                    <h4 className="section-title">Inviter un utilisateur</h4>
                    <Card className="card">
                        <AdminUserInvitation onInvitationSent={onInvitationSent} />
                    </Card>
                </Box>
                <Box sx={{marginBottom: '3rem'}}>
                    <h4 className="section-title">Utilisateurs invités</h4>
                    <AdminInvitedUsers invitedUsers={invitedUsers} />
                </Box>
                <Box sx={{marginBottom: '3rem'}}>
                    <h4 className="section-title">Utilisateurs</h4>
                    <AdminFlexTable userData={users}/>
                </Box>
            </div>
        </Box>
    );
}

export default Admin;