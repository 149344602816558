import React from 'react';
import { Box, Button, Divider, Drawer, Typography, useMediaQuery } from '@mui/material';
import {
    HomeOutlined,
    ConfirmationNumberOutlined,
    BookmarkBorder,
    BarChart,
    AdminPanelSettingsOutlined
} from "@mui/icons-material";
import {Link} from 'react-router-dom';
import {formatDate} from "../../utilities";

import {NavItem} from "./nav-item";
import {useCurrentUser} from "../AuthProvider";


const items = [
    {
        href: '/',
        icon: (<HomeOutlined fontSize="small" />),
        title: 'Accueil'
    },
    {
        href: '/ticket',
        icon: (<ConfirmationNumberOutlined fontSize="small" />),
        title: 'Mes tickets'
    },
    {
        href: '/clubs',
        icon: (<BookmarkBorder fontSize="small" />),
        title: 'Clubs'
    },
    {
        href: '/statistics',
        icon: (<BarChart fontSize="small" />),
        title: 'Statistiques'
    },
];

export const DashboardSidebar = (props) => {

    const {currentUser} = useCurrentUser();

    const { open, onClose } = props;
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
        defaultMatches: true,
        noSsr: false
    });

    const content = (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                }}
            >
                <div>
                    <Box sx={{ p: 3, display: 'flex' }}>
                        <Link to="/">
                            <img className="logo" src={require('../../assets/images/logo-small.png')} alt=""/>
                        </Link>

                        <Typography
                            color="neutral.400"
                            variant="body1"
                            sx={{
                                flexGrow: 1,
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'flex-end'
                            }}
                        >
                            {currentUser.club.city}
                        </Typography>
                    </Box>
                    <Box sx={{ px: 2 }}>
                        <Box
                            style={{
                                alignItems: 'center',
                                backgroundColor: 'rgba(255, 255, 255, 0.04)',
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'space-between',
                                px: 3,
                                padding: '11px',
                                borderRadius: 8,

                            }}
                        >
                            <div>
                                <Typography
                                    color="neutral.400"
                                    variant="body2"
                                >
                                    Inscrit jusqu'au {formatDate(currentUser.membership_expire)}
                                </Typography>
                            </div>
                        </Box>
                    </Box>
                </div>
                <Divider
                    sx={{
                        borderColor: '#2D3748',
                        my: 3
                    }}
                />
                <Box>
                    {items.map((item) => (
                        <NavItem
                            key={item.title}
                            icon={item.icon}
                            href={item.href}
                            title={item.title}
                            onClose={onClose}
                        />
                    ))}
                </Box>

                {currentUser.is_management &&
                    <Box>
                        <NavItem
                            title='Administration'
                            href="/administration"
                            onClose={onClose}
                            icon={<AdminPanelSettingsOutlined fontSize="small"/>}
                        />
                    </Box>
                }

                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        padding: '30px 16px 0 16px',
                    }}
                >
                    <Link
                        to="ticket/create"
                        className="navlink"
                    >

                        <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            fullWidth
                            onClick={onClose}
                        >
                            Créer un ticket
                        </Button>
                    </Link>
                </Box>
            </Box>
        </>
    );

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        backgroundColor: 'neutral.900',
                        color: '#FFFFFF',
                        width: 280
                    }
                }}
                variant="permanent"
            >
                {content}
            </Drawer>
        );
    }

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    backgroundColor: 'neutral.900',
                    color: '#FFFFFF',
                    width: 280
                }
            }}
            sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
            variant="temporary"
        >
            {content}
        </Drawer>
    );
};

