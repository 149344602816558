import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Stack, Typography} from "@mui/material";


function AdminInvitedUsers(props) {
    const {invitedUsers} = props

    return (
        <>
            {invitedUsers.length > 0
                ? (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table" className="admin-user-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="admin-user-table-header-td">Email</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invitedUsers.map((user) => (
                                    <TableRow
                                        key={user.email}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>{user.email}</TableCell>
                                    </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Stack p={2} bgcolor="#EEE" borderRadius={2}>
                        <Typography>Aucune invitation en cours</Typography>
                    </Stack>
                )
            }
        </>
    )
}

export default AdminInvitedUsers;