import React, {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AdminUserModalForm from "./AdminUserModalForm";
import axiosInstance from "../axios";


function AdminFlexTable(props) {
    const {userData} = props;
    const [user, setUser] = useState();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [statusChoices, setStatusChoices] = useState(null)

    const onTableRowClick = (data) => {
        setUser(data);
        setDialogOpen(true);
    }
    const handleClose = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        axiosInstance
            .get(`user/status/`)
            .then((res) => setStatusChoices(res.data))
    }, [])

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table" className="admin-user-table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="admin-user-table-header-td">Utilisateur</TableCell>
                            <TableCell className="admin-user-table-header-td" align="left">Inscription</TableCell>
                            <TableCell className="admin-user-table-header-td" align="left">Expire</TableCell>
                            <TableCell className="admin-user-table-header-td" align="left">Statut</TableCell>
                            <TableCell className="admin-user-table-header-td" align="left">Actif</TableCell>
                            <TableCell className="admin-user-table-header-td" align="left">Admin</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userData.map((row) => (
                            <TableRow
                                key={row.email}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                onClick={() => onTableRowClick(row)}
                            >
                                <TableCell>
                                    {row.name}<br/><span className="admin-user-email">{row.email}</span>
                                </TableCell>
                                <TableCell align="left">{new Date(row.created_date).toLocaleDateString()}</TableCell>
                                <TableCell align="left">{
                                    row.membership_expire && new Date(row.membership_expire).toLocaleDateString()}</TableCell>
                                <TableCell align="left">{row.status_display}</TableCell>
                                <TableCell align="left">{
                                    row.is_active
                                        ? <CheckCircleIcon color="success" fontSize="small"/>
                                        : <CancelIcon color="error" fontSize="small"/>
                                }</TableCell>
                                <TableCell align="left">{
                                    row.is_management
                                        ? <CheckCircleIcon color="success" fontSize="small"/>
                                        : <CancelIcon color="error" fontSize="small"/>
                                }</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {
                user &&
                <AdminUserModalForm
                    selectedUser={user}
                    open={dialogOpen}
                    onClose={handleClose}
                    statusChoices={statusChoices}
                />
            }
        </>
    )
}

export default AdminFlexTable;