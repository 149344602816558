import React, {useState} from 'react';
import './TicketStepForm.css';
import {Alert, Box, Button, Card, TextareaAutosize, TextField} from "@mui/material";
import axiosInstance from "../../../axios";
import {useCurrentUser} from "../../../components/AuthProvider";
import Dialog from "@mui/material/Dialog";

function TicketStepForm(props) {
    const {ticket, setTicket} = props;
    const {currentUser} = useCurrentUser();

    const [comment, setComment] = useState();
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [turnover, setTurnover] = useState(null)
    const [turnoverDisplay, setTurnoverDisplay] = useState()

    const [alertMessage, setAlertMessage] = useState();
    const [alertSeverity, setAlertSeverity] = useState();

    const setAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
    }

    const totalSteps = 3;

    const nextStep = ticket.steps
        .filter(step => !step.validated_date)
        .sort((a,b) => a.status - b.status)[0];


    const isLastStep = () => {
        return nextStep.status === 3
    }

    const submitStep = (accepted) => {
        const data = {
            success: accepted,
            comment: comment,
        }

        isLastStep() ? console.log('derniere etape') : console.log('etape');


        axiosInstance
            .put(`step/${nextStep.id}/`, data)
            .then(() => {

                setComment('')
                return axiosInstance
                    .get(`ticket/${ticket.id}`)
            })
            .then((res) => {
                setTicket(res.data);
            })
        setAlert('success', "L'étape a été sauvegardée")
    }

    const onSubmitStep = (accepted) => {
        !accepted
            ?
            comment
                ?
                handleDialogOpen(accepted)
                :
                setAlert('error', 'Vous devez entrer un commentaire pour refuser')
            :
            isLastStep()
                ?
                handleDialogOpen(accepted)
                :
                submitStep(accepted)
    }

    const handleDialogOpen = (accepted) => {
        setTurnoverDisplay(accepted);
        console.log('HANDLE DIALOG OPEN', accepted)
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };
    const handleCloseConfirm = () => {
        setTicketTurnover();
        submitStep(isLastStep());
        setDialogOpen(false);
    }
    const setTicketTurnover = () => {
        console.log('turnover', turnover)
        turnover &&
        axiosInstance
            .patch(`ticket/${ticket.id}/`, {turnover: parseFloat(turnover.replace(',', '.'))})
    }

    return (
        <Card className="p-2 mt-2">

            <h5 className="">
                Etape { nextStep.status }/{ totalSteps } - { nextStep.status_name }
            </h5>

            <div className="mt-1">
                <TextareaAutosize
                    minRows={8}
                    placeholder="Ajouter une description"
                    className="form-text-area"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />

                <Box className="pt-2 pb-1">

                    <Button onClick={() => onSubmitStep(true)} className="ticket-detail-step-button" variant="contained">
                        Accepter
                    </Button>
                    <Button onClick={() => onSubmitStep(false)} className="ticket-detail-step-button" variant="outlined" >
                        Refuser
                    </Button>
                </Box>
            </div>

            {(alertSeverity && alertMessage) &&
                <Alert severity={alertSeverity} className="alert">
                    {alertMessage}
                </Alert>
            }

            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="ticket-detail-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div id="ticket-detail-dialog-title" className="p-2 ticket-detail-dialog-title">
                    {"Fermeture du ticket"}
                </div>
                <div className="p-2">
                    {turnoverDisplay &&
                        <div className="ticket-detail-dialog-turnover mt-1">
                            <div className="ticket-detail-dialog-label">
                                Chiffre d'affaire <span className="annotation">(optionnel)</span>
                            </div>
                            <TextField size="small" defaultValue={turnover} onChange={(e) => {
                                setTurnover(e.target.value)
                            }}/>
                        </div>
                    }
                </div>
                <div className="ticket-detail-dialog-actions p-2">
                    <Button variant="contained" className="mr-05" onClick={handleCloseConfirm}>Fermer le ticket</Button>
                    <Button variant="outlined" onClick={handleDialogClose}>
                        Annuler
                    </Button>
                </div>
            </Dialog>
        </Card>
    );
}

export default TicketStepForm;