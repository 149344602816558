import React, {useState} from 'react';
import {Box, Button} from "@mui/material";

function StatsUserToggleButtons(props) {
    const { updateFunction } = props;
    const [activeButtonName, setActiveButtonName] = useState('created')

    const onButtonClick = (buttonName) => {
        setActiveButtonName(buttonName)
        updateFunction(buttonName)
        // console.log(buttonName)
        // console.log(activeButtonName === 'created' ? "stats-btn stats-btn-active": 'stats-btn')
    }
    return (

        <Box sx={{width: '100%', display: 'flex', marginTop: '1rem'}}>
            <Button
                name="created"
                className={activeButtonName === 'created' ? "stats-btn stats-btn-active": 'stats-btn'}
                onClick={(e) => {onButtonClick(e.target.name)}}
            >
                Tickets créés
            </Button>
            <Button
                name="received"
                className={activeButtonName === 'received' ? "stats-btn stats-btn-active": 'stats-btn'}
                onClick={(e) => {onButtonClick(e.target.name)}}
            >
                Tickets reçus
            </Button>
        </Box>
    );
}

export default StatsUserToggleButtons;