import React, {useState} from 'react';
import {Alert, Button} from "@mui/material";
import axiosInstance from "../../axios";
import { saveAs } from 'file-saver';

function ExportData(props) {

    const [alertMessage, setAlertMessage] = useState();
    const [alertSeverity, setAlertSeverity] = useState();

    const setAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
    }

    const exportData = () => {
        axiosInstance
            .get(`stats/export`)
            .then((res) => {
                // console.log(res)
                const csvData = new Blob([res.data], {type: 'text/csv;charset=utf-8'})
                saveAs(csvData, 'test.csv')
            })
        // console.log('export data')
        setAlert('success', 'Export des données effectué')
    }
    return (
        <div>
            <div className="mb-1">Exportez mes données (CSV)</div>
            <Button onClick={exportData} variant="contained">Exporter</Button>

            {(alertSeverity && alertMessage) &&
                <Alert severity={alertSeverity} className="alert">
                    {alertMessage}
                </Alert>
            }
        </div>
    );
}

export default ExportData;