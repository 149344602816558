import {useState, createContext, useEffect, useContext} from "react";
import axiosInstance from "../axios";

/**
 * https://stackoverflow.com/questions/70295336/how-do-i-share-state-between-routes-in-react-router
 */

export const UserContext = createContext({});

export const CurrentUserProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null)

    useEffect(() => {
        axiosInstance
            .get(`user/profile/`)
            .then((res) => {
                setCurrentUser(res.data);
                console.log("USER", res.data)
            })
            .catch((err) => {
                console.log('AUTH GUARD', err)
                window.location.replace('/login')
                // const refreshToken = localStorage.getItem('refresh_token')
                // if (refreshToken) {
                //     axiosInstance
                //         .post('token/refresh/', { refresh: refreshToken })
                //         .then((response) => {
                //             console.log('refreshed')
                //             localStorage.setItem('access_token', response.data.access);
                //             window.location.reload();
                //         })
                //         .catch((err) => {
                //             console.log('case 7.2')
                //             console.log(err);
                //             localStorage.removeItem('access_token')
                //             localStorage.removeItem('refresh_token')
                //             window.location.replace('/login')
                //         })
                // }
                // else {
                //     localStorage.removeItem('access_token')
                //     localStorage.removeItem('refresh_token')
                //     window.location.replace('/login')
                // }
            })
    }, [])


    return (
        <>
            {currentUser &&
                <UserContext.Provider value={{ currentUser, setCurrentUser }}>
                    {children}
                </UserContext.Provider>
            }
        </>
    )
}

export const useCurrentUser = () => useContext(UserContext)
