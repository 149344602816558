function parseJwt(token) {
    if (!token) { return; }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
}

export function getUserId() {
    let parsedJwt = parseJwt(localStorage.getItem('access_token'));
    return parsedJwt.user_id
}


export function formatDate(dateString) {
    return (
        new Date(dateString)
            .toLocaleDateString(
                'fr-fr',
                {year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'utc'}
            )
    );
}