import React, {useEffect, useState} from 'react';
import './UserProfile.css'
import axiosInstance from "../../axios";
import {useCurrentUser} from "../AuthProvider";
import {
    Alert,
    Avatar,
    Box,
    Button,
    Card,
    MenuItem,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Input,
    InputLabel,
    TextareaAutosize,
    TextField,
    Typography
} from "@mui/material";
import {useForm} from "react-hook-form";
import PasswordChangeModal from "../PasswordChange";
import Spinner from "../Spinner";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function UserProfile(props) {
    const {currentUser} = useCurrentUser();
    const { register, handleSubmit, reset } = useForm();

    const [avatar, setAvatar] = useState(null)
    const [avatarPreview, setAvatarPreview] = useState(currentUser.avatar)
    const [logo, setLogo] = useState(null)
    const [logoPreview, setLogoPreview] = useState(currentUser.business_logo)

    const [editEnabled, setEditEnabled] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selfEmployed, setSelfEmployed] = useState(currentUser.self_employed)

    const [isLoading, setIsLoading] = useState(false)

    const [alertMessage, setAlertMessage] = useState();
    const [alertSeverity, setAlertSeverity] = useState();

    const setAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
    }


    const [sectors, setSectors] = useState([])

    /**
     * Close the Dialog window
     */
    const handleClose = () => {
        setDialogOpen(false);
    };

    /**
     * Get list of available business sectors
     */
    useEffect(() => {
        setIsLoading(true);
        axiosInstance
            .get(`user/sectors/`)
            .then((res) => {
                setSectors(res.data)
                setIsLoading(false)
            })
    }, [setSectors])

    /**
     * Show avatar preview when user select a new one
     * @param {event} event
     */
    const onAvatarChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setAvatarPreview(URL.createObjectURL(event.target.files[0]));
            setAvatar(event.target.files[0])
        }
    }

    const onLogoChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setLogoPreview(URL.createObjectURL(event.target.files[0]));
            setLogo(event.target.files[0])
        }
    }

    /**
     * Toggle editable state of the form.
     *
     */
    const toggleEdit = () => {
        setEditEnabled(!editEnabled)
        reset()
    }

    const userSectorId = () => {
        return sectors.find(sector => sector.id === currentUser['business_sector'])
    }

    /**
     * Send form data to API to update User info.
     * Display an alert message
     *
     * @param data Registered form data from react-hook-form handleSubmit()
     */
    const onSubmit = (data) => {
        let formData = new FormData();
        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        })
        avatar && formData.append('avatar', avatar, avatar.name);
        logo && formData.append('business_logo', logo, logo.name);

        for (const key of formData.entries()) {
            console.log(key[0] + ', ' + key[1]);
        }

        axiosInstance
            .patch(`user/${currentUser.id}/`, formData,
                {headers: {'Content-Type': 'multipart/form-data'}}
            )
            .then((res) => {
                setEditEnabled(false)
                // res.status !== 200 && setAlert('error', 'Une erreur est survenue')
                // window.location.reload()
            })
            .catch(error => console.error(error))
    }

    /**
     * Toggle the selfEmployed state
     */
    const toggleSelfEmployed = () => {
        setSelfEmployed(!selfEmployed)
    }

    if (isLoading || !sectors.length) { return <Spinner/>}
    return (
        <>
            <PasswordChangeModal onClose={handleClose} open={dialogOpen}/>

            {(alertSeverity && alertMessage) &&
                <Alert severity={alertSeverity} className="alert">
                    {alertMessage}
                </Alert>
            }

            <form onSubmit={handleSubmit(onSubmit)} className="">
                <div>
                    {editEnabled === true ?
                        <>
                            <Button
                                variant="outlined"
                                onClick={toggleEdit}
                                sx={{marginRight: '2rem', maxHeight: '40px'}}
                            >
                                Annuler
                            </Button>

                            <Button
                                type='submit'
                                variant="contained"
                                sx={{maxHeight: '40px'}}
                            >
                                Enregistrer
                            </Button>
                        </>
                        : <Button
                            variant="contained"
                            onClick={toggleEdit}
                            className="profile-edit-button"
                        >
                            Editer
                        </Button>
                    }
                </div>

                <div className="profile-cards-container mt-1">
                    <div className="profile-card">

                        <Card>
                            <div className="p-2">
                                <Avatar src={avatarPreview} sx={{ width: 65, height: 65, margin: '0 auto'}} />
                                {editEnabled &&
                                    <div className="user-image-btn-container">

                                        <InputLabel htmlFor="avatar" className="upload-file-btn">
                                            Changer l'image
                                            <Input
                                                id="avatar" type="file" sx={{display: 'none'}}
                                                onChange={onAvatarChange}/>

                                        </InputLabel>

                                    </div>
                                }

                                <Typography sx={{width: '100%', marginTop: '1rem'}}>Nom complet</Typography>
                                <TextField
                                    {...register('name', {required: true})}
                                    size="small"
                                    disabled={!editEnabled}
                                    defaultValue={currentUser.name}
                                    fullWidth
                                />
                                <Typography sx={{width: '100%', marginTop: '1rem'}}>Téléphone</Typography>
                                <TextField
                                    size="small"
                                    disabled={!editEnabled}
                                    {...register('phone_number', {required: true})}
                                    defaultValue={currentUser.phone_number}
                                    fullWidth
                                />
                                <Typography sx={{width: '100%', marginTop: '1rem'}}>Email</Typography>
                                <TextField
                                    size="small"
                                    disabled={!editEnabled}
                                    {...register('email', {required: true})}
                                    defaultValue={currentUser.email}
                                    fullWidth
                                />
                                <FormGroup sx={{marginTop: '.6rem'}}>
                                    <FormControlLabel
                                        disabled={!editEnabled}
                                        onClick={toggleSelfEmployed}
                                        control={<Checkbox {...register('self_employed')} checked={selfEmployed}/>}
                                        label="Travailleur non salarié" />
                                </FormGroup>
                            </div>

                        </Card>
                    </div>
                    <div className="profile-card">

                        <Card>
                            <div className="card-body card-body-profile">
                                <Avatar src={logoPreview} sx={{ width: 65, height: 65, margin: '0 auto'}} />
                                {editEnabled &&
                                    <div className="user-image-btn-container">
                                        <InputLabel htmlFor="logo" className="upload-file-btn">
                                            Changer l'image
                                            <Input
                                                id="logo" type="file" sx={{display: 'none'}}
                                                onChange={onLogoChange}/>

                                        </InputLabel>

                                    </div>
                                }

                                <Typography sx={{width: '100%', marginTop: '1rem'}}>Raison sociale</Typography>
                                <TextField
                                    size="small"
                                    disabled={!editEnabled}
                                    {...register('business_name', {required: true})}
                                    defaultValue={currentUser.business_name}
                                    fullWidth
                                />

                                <Typography sx={{width: '100%', marginTop: '1rem'}}>Adresse</Typography>
                                <TextField
                                    size="small"
                                    disabled={!editEnabled}
                                    {...register('business_address', {required: true})}
                                    defaultValue={currentUser.business_address}
                                    fullWidth
                                />

                                <Typography sx={{width: '100%', marginTop: '1rem'}}>Secteur d'activité</Typography>
                                <TextField
                                    select
                                    disabled={!editEnabled}
                                    {...register('business_sector', {required: 'Champ requis'})}
                                    size="small"
                                    defaultValue={userSectorId()?.id}
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',

                                    }}
                                >
                                    {sectors.map((sector) => (
                                        <MenuItem key={sector.id} value={sector.id}>
                                            {sector.name}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <Typography sx={{width: '100%', marginTop: '1rem'}}>
                                    Description des services fournis
                                </Typography>
                                <Box sx={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between'}}>

                                    <TextareaAutosize
                                        className={!editEnabled === true ? 'tdisabled': 'tenabled'}
                                        disabled={!editEnabled}
                                        {...register('business_description')}
                                        minRows={8}
                                        placeholder="Description des services fournis"
                                        defaultValue={currentUser.business_description}
                                        style={{
                                            width: 'calc(100%)',
                                            lineHeight: '1.4375em',
                                            fontFamily: '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
                                            border: '1px solid #ddd',
                                            padding: '1em',
                                            borderRadius: '8px',
                                        }}
                                    />
                                </Box>
                            </div>

                        </Card>
                    </div>
                </div>
            </form>

        </>
    );
}


export default UserProfile;