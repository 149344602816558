import * as React from 'react';
import './TicketTimeline.css'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import {formatDate} from "../../../utilities";
import {Box, Card} from "@mui/material";
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';


function TicketTimeline(props) {
    const { ticket } = props;
    const steps = ticket.steps;

    return (

        <Card className="mt-2">
            <Box className="card-title-container">
                <AccessTimeFilledIcon className="card-title-icon"/>
                <h4>Timeline</h4>
            </Box>
            <Timeline className="timeline">
                <TimelineItem className="timeline-item">
                    <TimelineSeparator>
                        <TimelineDot className="active" />
                        <TimelineConnector className="active" />
                    </TimelineSeparator>
                    <TimelineContent>
                        <div className="timeline-item-label">
                            Créé
                        </div>
                        <div className="timeline-item-date">
                            {formatDate(ticket.created_date)}
                        </div>
                    </TimelineContent>
                </TimelineItem>

                {steps.map((step) => (
                    <TimelineItem key={step.status} className="timeline-item">
                        <TimelineSeparator>
                            <TimelineDot className={step.success !== null ? "active" : ""}/>
                            <TimelineConnector className={step.success !== null ?  "active" : ""}/>
                        </TimelineSeparator>
                        <TimelineContent>

                            <div className="timeline-item-label">
                                {step.status_name}
                            </div>
                            <div className="timeline-item-date">
                                {step.validated_date && formatDate(step.validated_date)}
                            </div>
                            <div className="timeline-item-comment">
                                {step.comment}
                            </div>
                        </TimelineContent>
                    </TimelineItem>
                ))}

                <TimelineItem className="timeline-item">
                    <TimelineSeparator>
                        <TimelineDot className={ticket.closed ? "active": ""}/>
                    </TimelineSeparator>
                    <TimelineContent>

                        <div className="timeline-item-label">
                            Fermé
                        </div>
                    </TimelineContent>

                </TimelineItem>
            </Timeline>
        </Card>
    );
}

export default TicketTimeline;