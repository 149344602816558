import React, {useEffect, useState} from 'react';
import './PageStats.css';
import {useCurrentUser} from "../../components/AuthProvider";
import axiosInstance from "../../axios";
import {Box, Card, MenuItem, Stack, TextField, Typography} from "@mui/material";
import UserAvatar from "../../components/UserAvatar";

import {DatePicker} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import format from 'date-fns/format';
import startOfYear from 'date-fns/startOfYear';
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StatsKeyNumbers from "./StatsKeyNumbers/StatsKeyNumbers";
import Spinner from "../../components/Spinner";
import StatsCharts from "./StatsCharts/StatsCharts";
import CancelIcon from '@mui/icons-material/Cancel';
import StatsUserToggleButtons from "./StatsUserToggleButtons";


const defaultDateFrom = () => {
    return format(
        startOfYear(new Date(Date.now())),
        'yyyy-MM-dd'
    )
}
const defaultDateTo = () => {
    return format(
        new Date(Date.now()),
        'yyyy-MM-dd'
    )
}

function PageStats(props) {
    /**
     * The current user context
     */
    const {currentUser} = useCurrentUser();

    /**
     * Stores the list of available clubs
     */
    const [clubs, setClubs] = useState([]);

    /**
     * Stores the list of club members
     */
    const [clubMembers, setClubMembers] = useState([]);

    /**
     * Stores the selected club data
     */
    const [currentClub, setCurrentClub] = useState(currentUser.club)

    /**
     * Stores the selected user ID
     */
    const [selectedUserId, setSelectedUserId] = useState('')

    /**
     * Stores all statistics (club and user statistics)
     */
    const [stats, setStats] = useState();

    /**
     *
     */
    const [userTicketCategory, setUserTicketCategory ] = useState('created');

    /**
     * Dates filter
     */
    const [ dateFrom, setDateFrom ] = useState(defaultDateFrom())
    const [ dateTo, setDateTo ] = useState(defaultDateTo())

    /**
     * Handle date change from Date Pickers
     * @param newValue date value from MUI DatePicker
     */
    const handleDateFromChange = (newValue) => {
        setDateFrom(
            (!isNaN(newValue) && newValue !== null)
                ? newValue.toISOString()
                :null
        )
    }
    const handleDateToChange = (newValue) => {
        setDateTo(
            (!isNaN(newValue) && newValue !== null)
                ? newValue.toISOString()
                :null
        )
    }

    /**
     * Triggered when the club members input changes
     * @param e input event.target.value
     */
    const onUserSelect = (e) => {
        setSelectedUserId(e)
    }

    /**
     * Retrieve club members
     * @param clubId Club ID
     */
    const getClubMembers = (clubId) => {
        axiosInstance
            .get(`club/members/?club_id=${clubId}`)
            .then((res) => {
                setClubMembers(res.data);
            })
    }

    /**
     * Triggered when the Clubs input value changes
     * @param e input event.target.value
     */
    const onClubChange = (e) => {
        getClubMembers(e)
        setCurrentClub(clubs.find(club => club.id === e))
        setSelectedUserId('')
    }

    /**
     * Remove the selected user in the Club mebers input
     */
    const onUserFilterCancel = () => {
        setSelectedUserId('')
    }

    useEffect(() => {
        // Get current user club data and club members data on first load
        !clubs.length &&
            axiosInstance
                .get(`club/`)
                .then((res) => {setClubs(res.data)})
        !clubMembers.length && getClubMembers(currentUser.club.id)

        // Get statistics data on every render
        getData()

    }, [dateTo, dateFrom, selectedUserId, currentClub])

    /**
     * Query statistics data based on selected date range, club and optional club member
     */
    const getData = () => {
        const queryFromDate = format(new Date(dateFrom), 'yyyy-MM-dd')
        const queryFromTo = format(new Date(dateTo), 'yyyy-MM-dd')
        let url =`stats/?club_id=${currentClub.id}&from_date=${queryFromDate}&to_date=${queryFromTo}`
        if (selectedUserId) {
            url = url + `&user_id=${selectedUserId}`
        }

        axiosInstance
            .get(url)
            .then((res) => {
                setStats(res.data)
            })
    }

    const displayUserTicketCategory = (buttonName) => {
        setUserTicketCategory(buttonName)
    }

    if ( !stats ) { return <Spinner/> }
    return (
        <Box className="page">
            <div className="page-wrapper">


                <Card sx={{padding: '2rem', marginTop: '3rem'}}>

                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>

                        <div className="stat-filter-row mb-1">

                        <div className="stats-filter">
                            <TextField
                                select
                                size="small"
                                label="Sélectionner un club"
                                defaultValue={currentUser.club.id}
                                variant="outlined"
                                className="stats-filter-select"
                                onChange={(e) => {
                                    onClubChange(e.target.value)
                                }}
                            >
                                {clubs.map((club) => (
                                    <MenuItem key={club.id} value={club.id}>
                                        {club.city}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>

                        <div className="stats-filter stats-filter-user">
                            <TextField
                                select
                                size="small"
                                label="Sélectionner un utilisateur"
                                defaultValue=''
                                value={selectedUserId}
                                variant="outlined"
                                className="stats-filter-select"
                                onChange={(e) => {
                                    onUserSelect(e.target.value)
                                }}
                            >
                                <MenuItem value='all'>Tous</MenuItem>
                                {clubMembers.map((member) => (
                                    <MenuItem key={member.id} value={member.id}>
                                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                                            <UserAvatar imageRelPath={member.avatar}/>
                                            <Typography sx={{marginLeft: '1rem'}}>{member.name}</Typography>
                                        </Box>
                                    </MenuItem>
                                ))}
                            </TextField>
                            { selectedUserId &&
                                <CancelIcon className="stats-filter-user-cancel" onClick={onUserFilterCancel}/>
                            }

                        </div>
                        </div>
                    </Box>
                    <Box className="date-pickers-container">
                        <LocalizationProvider dateAdapter={AdapterDateFns} >
                            <Stack spacing={1} className="admin-date-picker">
                                <DatePicker
                                    inputFormat="dd/MM/yyyy"
                                    value={dateFrom}
                                    onChange={handleDateFromChange}
                                    label={"Depuis le"}
                                    renderInput={(params) => <TextField {...params}/>}
                                />
                            </Stack>
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns} >
                            <Stack spacing={1} className="admin-date-picker">
                                <DatePicker
                                    inputFormat="dd/MM/yyyy"
                                    value={dateTo}
                                    onChange={handleDateToChange}
                                    renderInput={(params) => <TextField {...params} />}
                                    label={"Jusqu'au"}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </Box>
                </Card>

                {!selectedUserId
                    ?
                        <>
                            <StatsKeyNumbers
                                stats={stats.club}
                                ticketLabel='Ticket(s) créé(s)'
                                ratingLabel='Moyenne des notes'
                                turnoverLabel="Chiffre d'affaires"
                            />
                            <StatsCharts stats={stats.club} showTicketRating={true}/>
                        </>

                    :
                        <>
                            <StatsUserToggleButtons updateFunction={displayUserTicketCategory}/>

                            {userTicketCategory === 'created'
                                ?
                                <>
                                    <StatsKeyNumbers
                                        stats={stats.user.created}
                                        ticketLabel='Ticket(s) créé(s)'
                                        ratingLabel='Moyenne des notes reçues'
                                        turnoverLabel="Chiffre d'affaires"
                                    />
                                    <StatsCharts stats={stats.user.created} showTicketRating={false}/>
                                </>
                                :
                                <>
                                    <StatsKeyNumbers
                                        stats={stats.user.received}
                                        ticketLabel='Ticket(s) reçu(s)'
                                        ratingLabel='Moyenne des notes attribuées'
                                        turnoverLabel="Chiffre d'affaires"
                                    />
                                    <StatsCharts stats={stats.user.received} showTicketRating={true}/>
                                </>
                            }
                        </>
                }
            </div>
        </Box>
    );
}


export default PageStats;