import React from 'react';
import TicketCreate from "../components/Ticket/TicketCreate/TicketCreate";


function PageCreateTicket(props) {

    return (
        <div className="page">
            <TicketCreate/>
        </div>
    );
}

export default PageCreateTicket;