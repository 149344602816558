import React from 'react';
import LatestTickets from "../components/Ticket/LatestTickets/LatestTickets";


function PageHome(props) {
    return (
        <div className="page">
            <div className="page-wrapper">

                <h2 className="page-title">ACCUEIL</h2>
                <LatestTickets/>
            </div>
        </div>
    );
}

export default PageHome;
