import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import {useForm} from "react-hook-form";
import {Alert, Box, TextField, Typography} from "@mui/material";
import axiosInstance from "../axios";
import {useState} from "react";


function PasswordChangeModal(props) {
    const { register, handleSubmit} = useForm();
    const { onClose, open } = props;
    const [alert, setAlert] = useState()

    const handleClose = () => {
        onClose();
    };

    const onSubmit = (data) => {
        axiosInstance
            .patch(`user/password-change/`, data)
            .then((res) => {
                setAlert(<Alert severity={res.data.status}>{res.data.message}</Alert>)
            })
            .catch(() => {
                setAlert(<Alert severity="error">Error</Alert>)

            })
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {alert && alert}
                <form onSubmit={handleSubmit(onSubmit)} className="modal-password-change">
                    <div className="wrapper">
                        <Typography variant="h6" sx={{paddingBottom: '1.5rem'}}>Changer mon mot de passe</Typography>
                        <Typography>Mot de passe actuel</Typography>
                        <TextField
                            {...register('old_password', {required: true})}
                            type="password"
                            size="small"
                            fullWidth
                        />
                        <Typography sx={{paddingTop: '1rem'}}>Nouveau mot de passe</Typography>
                        <TextField
                            {...register('new_password_1', {required: true})}
                            type="password"
                            size="small"
                            fullWidth
                        />
                        <Typography sx={{paddingTop: '1rem'}}>Confirmation</Typography>
                        <TextField
                            {...register('new_password_2', {required: true})}
                            type="password"
                            size="small"
                            fullWidth
                        />
                        <Box
                            sx={{textAlign: 'right', paddingTop: '1rem'}}
                        >
                            <Button onClick={handleClose} sx={{marginRight: '1rem'}}>Annuler</Button>
                            <Button variant="contained" type="submit">
                                Envoyer
                            </Button>
                        </Box>
                    </div>
                </form>
            </Dialog>
        </div>
    );
}

export default PasswordChangeModal;