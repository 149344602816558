import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    MenuItem,
    TextField,
    Typography,
    Dialog,
    Stack, Avatar, TextareaAutosize
} from "@mui/material";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {useForm} from "react-hook-form";
import axiosInstance from "../axios";
import DatePicker from '@mui/lab/DatePicker';
import format from "date-fns/format";



function AdminUserModalForm(props) {
    const { onClose, selectedUser, open, statusChoices } = props;
    const [isManagement, setIsManagement] = useState();
    const [userStatus, setUserStatus] = useState();
    const [subscriptionExpiration, setSubscriptionExpiration] = useState()

    const { register, handleSubmit, formState} = useForm();
    const { errors } = formState;

    const handleSubscriptionDateChange = (newValue) => {
        // TODO all date related components and function should handle Timezones
        setSubscriptionExpiration(
            (!isNaN(newValue) && newValue !== null)
                ? format(newValue, 'yyyy-MM-dd') + 'T12:00:00.999Z'
                :null
        )
    };

    const handleClose = () => {
        onClose(selectedUser);
    };

    const onUserActivate = () => {
        const data = {
            user_id: selectedUser.id,
            is_active: !selectedUser.is_active
        }
        axiosInstance
            .post(`user/activate/`, data)
            .then(() => {
                window.location.reload()
            })
    }

    useEffect(() => {
        setSubscriptionExpiration(selectedUser.membership_expire)
        setIsManagement(selectedUser.is_management)
        setUserStatus(selectedUser.status)

    }, [selectedUser])

    const onSubmit = (data) => {
        data = {
            user_id: selectedUser.id,
            membership_expire: subscriptionExpiration,
            ...data
        }
        axiosInstance
            .patch(`user/${selectedUser.id}/`, data)
            .then((res) => {
                window.location.reload()
            })
    }

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            maxWidth="lg"
            className="admin-modal"
            PaperProps={{ className: "admin-modal-paper-props" }}
        >

            <Box sx={{padding: "2em"}}>
                <Box className="admin-modal-title" sx={{padding: '1rem 0 1.5rem 0'}}>
                    <Typography sx={{fontWeight: 600}}>Activer/Désactiver l'utilisateur</Typography>
                    <Button variant="contained" size="small" onClick={onUserActivate}>{selectedUser.is_active ? 'Désactiver' : 'Activer'}</Button>
                </Box>

                <Typography sx={{fontWeight: 600}}>Modifier les informations</Typography>

                <Box sx={{border: '1px solid #ddd', padding: '1rem'}}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}
                               className="admin-date-picker"
                               sx={{marginBottom: '1rem'}}>
                            <DatePicker
                                inputFormat="dd/MM/yyyy"
                                value={subscriptionExpiration !== null ? subscriptionExpiration : null}
                                onChange={handleSubscriptionDateChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>

                    <Typography variant="body1" sx={{width: '100%'}}>
                        Status
                    </Typography>
                    <TextField
                        select
                        size="small"
                        defaultValue={selectedUser.status}
                        variant="outlined"
                        {...register('status', {required: true, valueAsNumber: true})}
                        error={!!errors?.name}
                        helperText={errors.member?.message}
                        sx={{ marginBottom: '1rem', width: '100%'}}

                    >
                        {statusChoices.map((status) => (
                            <MenuItem key={status.id} value={status.id}>
                                {status.value}
                            </MenuItem>
                        ))}
                    </TextField>

                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    defaultChecked={selectedUser.is_management}
                                    {...register('is_management')}
                                />}
                            label="Admin"
                        />
                    </FormGroup>

                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button
                            variant="outlined"
                            sx={{marginRight: '2em'}}
                            onClick={handleClose}
                        >
                            Annuler
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                        >
                            Sauvegarder
                        </Button>
                    </Box>
                </form>

                </Box>

                <Typography sx={{fontWeight: 600, margin: '1rem 0'}}>
                    Profil
                </Typography>
                <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: "2rem"}}>

                    <Avatar src={selectedUser.avatar} sx={{width: 85, height: 85}}>
                        {selectedUser.name.split(" ").map((n)=>n[0])}
                    </Avatar>

                </Box>
                <Typography variant="body1" sx={{width: '100%'}}>Nom</Typography>
                <TextField
                    size="small"
                    disabled
                    defaultValue={selectedUser.name}
                    sx={{ marginBottom: '1rem', width: '100%'}}
                />

                <Typography variant="body1" sx={{width: '100%'}}>Email</Typography>
                <TextField
                    disabled
                    size="small"
                    defaultValue={selectedUser.email}
                    sx={{ marginBottom: '1rem', width: '100%'}}
                    error={!!errors?.message}
                />
                <Typography variant="body1" sx={{width: '100%'}}>Téléphone</Typography>
                <TextField
                    disabled
                    size="small"
                    defaultValue={selectedUser.phone_number}
                    sx={{ marginBottom: '1rem', width: '100%'}}
                    error={!!errors?.message}
                />

                <Typography variant="body1" sx={{width: '100%'}}>Inscrit le</Typography>
                <TextField
                    size="small"
                    disabled
                    value={new Date(selectedUser.created_date).toLocaleDateString()}
                    sx={{width: '100%', marginBottom: '1rem'}}
                />

                <FormGroup className="mb-1">
                    <FormControlLabel
                        control={<Checkbox defaultChecked={selectedUser.self_employed} disabled/>}
                        label="Travailleur non salarié" />
                </FormGroup>

                <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>

                    <Avatar src={selectedUser.business_logo} sx={{width: 85, height: 85}}>
                        {selectedUser.business_name.split(" ").map((n)=>n[0])}
                    </Avatar>

                </Box>

                <Typography variant="body1" sx={{width: '100%'}}>Raison sociale</Typography>
                <TextField
                    size="small"
                    disabled
                    value={selectedUser.business_name}
                    sx={{width: '100%', marginBottom: '1rem'}}
                />

                <Typography variant="body1" sx={{width: '100%'}}>Adresse</Typography>
                <TextField
                    size="small"
                    disabled
                    value={selectedUser.business_address}
                    sx={{width: '100%', marginBottom: '1rem'}}
                />

                <Typography variant="body1" sx={{width: '100%'}}>Secteur</Typography>
                <TextField
                    size="small"
                    disabled
                    value={selectedUser.business_sector}
                    sx={{width: '100%', marginBottom: '1rem'}}
                />

                <Typography variant="body1" sx={{width: '100%'}}>Description des services fournis</Typography>
                <div className="mb-1">
                    <TextareaAutosize
                        minRows={8}
                        placeholder="Description des services fournis"
                        className="form-text-area"
                        value={selectedUser.business_description}
                    />
                </div>
            </Box>
        </Dialog>
    );
}

export default AdminUserModalForm;