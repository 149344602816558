import React from 'react';
import {Avatar} from "@mui/material";
// import {BASE_URL} from "../index";


function UserAvatar(props) {
    const { imageRelPath, defaultText, width, height, } = props
    return (

        <Avatar
            src={imageRelPath}
            sx={{ width: width || 35, height: height || 35, fontSize: '1rem'}}
        >
            {defaultText && defaultText.split(" ").map((n)=>n[0])}
        </Avatar>
    );
}

export default UserAvatar;
