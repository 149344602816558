import React, {useEffect, useState} from 'react';
import axiosInstance from "../../../axios";
import {useCurrentUser} from "../../AuthProvider";
import {formatDate} from "../../../utilities";
import Spinner from "../../Spinner";
import TicketList from "../TicketList";
import BasicTabs from "../TicketListTab";
import TicketRating from "../TicketRating/TicketRating";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenIcon from "@mui/icons-material/LockOpen";

function MyTickets() {
    const { currentUser } = useCurrentUser();
    const [ticketList, setTicketList] = useState([])
    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {
        setIsLoading(true);
        axiosInstance
            .get(`ticket/`)
            .then((res) => {
                setIsLoading(false);
                setTicketList(res.data);
            })
    }, []);

    const dataColumnsDefault = [
        // { field: 'id', headerName: 'ID', flex: 1, headerClassName: 'datagrid-header'},

        { field: 'client_name', headerName: 'Client', width: 150, minWidth: 100, headerClassName: 'datagrid-header', },
        { field: 'title', headerName: 'Titre', width: 200, flex: 1, headerClassName: 'datagrid-header' },
        {
            field: 'created_date',
            headerName: 'Création',
            headerClassName: 'datagrid-header',
            valueFormatter: (params) => {return formatDate(params.value)},
        },
        {
            field: 'rating',
            headerName: 'note',
            align: 'center',
            headerClassName: 'datagrid-header',
            headerAlign: 'center',
            renderCell: (params => {
                return <TicketRating disabled size='small' ticket={params.row}/>
            })
        }
    ]

    const receivedTicketDataColumns = [
        {
            field: 'closed',
            headerName: '',
            headerClassName: 'datagrid-header',
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return params.value
                    ? <LockOutlinedIcon fontSize="small" color="error"/>
                    : <LockOpenIcon fontSize="small" color="success"/>
            }
        },
        { field: 'creator', headerName: 'Créateur', width: 150, headerClassName: 'datagrid-header',
            valueGetter: (params) => { return params.value.name}
        },
    ].concat(dataColumnsDefault)

    const sentTicketDataColumns = [
        {
            field: 'closed',
            headerClassName: 'datagrid-header',
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                return params.value
                    ? <LockOutlinedIcon fontSize="small" color="error"/>
                    : <LockOpenIcon fontSize="small" color="success"/>
            }
        },
        { field: 'assignee', headerName: 'Destinataire', width: 150, headerClassName: 'datagrid-header',
            valueGetter: (params) => { return params.value.name}
        },
    ].concat(dataColumnsDefault)

    const sentTicketDataRows = ticketList.filter((obj) => obj.creator.id === currentUser.id);
    const receivedTicketDataRows = ticketList.filter((obj) => obj.assignee.id === currentUser.id);

    if (isLoading) { return <Spinner />}
    return (
        <div className="page">

            <div className="page-wrapper">

                <h2 className="page-title">MES TICKETS</h2>
                <BasicTabs
                    receivedTicketList={
                        <TicketList
                            dataRows={receivedTicketDataRows}
                            dataColumns={receivedTicketDataColumns}
                        />}
                    sentTicketList={
                        <TicketList
                            dataRows={sentTicketDataRows}
                            dataColumns={sentTicketDataColumns}
                        />
                    }
                />
            </div>
        </div>
    );
}

export default MyTickets;