import React from 'react';
import UserProfileTabs from "../components/UserProfile/UserProfileTabs";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function PageUserProfile(props) {

    return (
        <div className="page">
            <div className="wrapper">
                <h2 className="page-title page-title-with-button">MON COMPTE</h2>
                <UserProfileTabs/>
            </div>

        </div>
    );
}


export default PageUserProfile;