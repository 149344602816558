import React from 'react';
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {Box} from "@mui/material";


function TicketClubBreakdown(props) {
    const { data } = props
    // console.log('DATA', data)
    return (
        <>
            { data.length !== 0
                ?
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        // width={500}
                        // height={300}
                        layout='vertical'
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 60,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis type="number" allowDecimals={false}/>
                        <YAxis type="category" dataKey='club_name'/>
                        <Tooltip />
                        <Legend />
                        <Bar
                            dataKey="from"
                            stackId='a'
                            fill="#2f82c2"
                            name="Ticket(s) reçu(s)"
                            barSize={20}
                        />
                        <Bar
                            dataKey="to"
                            stackId='a'
                            fill="#81E4DA"
                            name="Ticket(s) envoyé(s)"
                            barSize={20}
                        />
                    </BarChart>
                </ResponsiveContainer>
                :

                <Box
                    sx={{
                        width: '100%',
                        textAlign: 'center',
                        paddingBottom: '1rem',
                        color: '#AAAAAA'
                    }}
                >
                    Aucun ticket
                </Box>
            }

        </>
    );
}

export default TicketClubBreakdown;


