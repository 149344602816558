import "./index.css";
import { Outlet } from "react-router-dom";
import {DashboardLayout} from "./components/navigation/dashboard-layout";
import {CurrentUserProvider} from "./components/AuthProvider";

/**
 * https://stackoverflow.com/questions/70295336/how-do-i-share-state-between-routes-in-react-router
 */


/**
 *
 * @returns {JSX.Element}
 */
export default function App() {
    return (
        <CurrentUserProvider>
            <DashboardLayout>
                <Outlet />
            </DashboardLayout>
        </CurrentUserProvider>
    );
}
