import React from 'react';
import './StatsCharts.css';
import {Box, Card, Typography} from "@mui/material";
import TicketRatingPie from "../../../components/charts/TicketRatingPie";
import TicketClubBreakdown from "../../../components/charts/TicketClubBreakdown";
import TurnoverLineChart from "../../../components/charts/TurnoverLineChart";
import TicketStatusPie from "../../../components/charts/TicketStatusPie";
import Spinner from "../../../components/Spinner";

function StatsCharts(props) {
    const { stats, showTicketRating } = props;

    /**
     * Define the height of the chart container based on the number of values
     * @returns {string|string}
     */
    const clubBreakdownHeight = () => {
        return stats.club_breakdown.length !== 0
            ?
            `${Object.keys(stats.club_breakdown).length * 110}px`
            :
            '40px'
    }

    if (!stats) { return <Spinner />}
    return (
        <Box className="stats-chart-container">
            {stats.ratings &&
                <Card className="stat-chart-card stat-chart-card-half">
                    <Box className="wrapper">
                        <Typography variant="h6" sx={{textAlign: 'center'}}>Statut des tickets</Typography>
                        <Box sx={{height: '300px'}}>
                            <TicketStatusPie data={stats.status}/>
                        </Box>
                    </Box>
                </Card>
            }

            {stats.ratings && showTicketRating &&
                <Card className="stat-chart-card stat-chart-card-half">
                    <Box className="wrapper">
                        <Typography variant="h6" sx={{textAlign: 'center'}}>Notes des tickets</Typography>
                        <Box sx={{height: '300px'}}>
                            <TicketRatingPie data={stats.ratings.breakdown}/>
                        </Box>
                    </Box>
                </Card>
            }

            {stats.turnover &&
                <Card className="stat-chart-card stat-chart-card-full">
                    <Box sx={{padding: '2rem'}}>

                        <Typography variant="h6" sx={{textAlign: 'center', marginBottom: '1rem'}}>Chiffre d'affaires mensuel</Typography>
                        <Box sx={{height: '300px'}}>
                            <TurnoverLineChart data={stats.turnover.monthly}/>
                        </Box>
                    </Box>
                </Card>
            }
            {stats.club_breakdown &&
                <Card className="stat-chart-card stat-chart-card-full">
                    <Box sx={{padding: '2rem'}}>
                        <Typography variant="h6" sx={{textAlign: 'center', marginBottom: '1rem'}}>Tickets inter-clubs</Typography>
                        <Box sx={{height: clubBreakdownHeight()}}>
                            <TicketClubBreakdown data={stats.club_breakdown}/>
                        </Box>
                    </Box>
                </Card>
            }

        </Box>
    );
}

export default StatsCharts;