import React, {useEffect, useState} from 'react';
import {useCurrentUser} from "../components/AuthProvider";
import axiosInstance from "../axios";
import {Avatar, Box, Button, Card, MenuItem, TextField, Tooltip, Typography} from "@mui/material";
import UserProfileModal from "../components/UserProfileModal";
import CloseIcon from '@mui/icons-material/Close';
import {Link} from "react-router-dom";
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import IconButton from "@mui/material/IconButton";


function Clubs(props) {
    const {currentUser} = useCurrentUser();

    const [clubs, setClubs] = useState([]);
    const [clubMembers, setClubMembers] = useState([]);
    const [displayMembers, setDisplayMembers] = useState([])
    const [currentClub, setCurrentClub] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)
    const [dialogOpen, setDialogOpen] = useState(false);

    const [sectorInput, setSectorInput] = useState('')

    const handleClose = () => {
        setDialogOpen(false);
    };

    useEffect(() => {

        getClubMembers(currentUser.club.id)

        axiosInstance
            .get(`club/`)
            .then((res) => {
                setClubs(res.data);
                setCurrentClub(res.data.find(club => club.id === currentUser.club.id))
            })
    }, [])

    const getClubMembers = (clubId) => {
        axiosInstance
            .get(`club/members/?club_id=${clubId}`)
            .then((res) => {
                setClubMembers(res.data);
                setDisplayMembers(res.data)
            })
    }

    const onSectorChange = (sector) => {
        setSectorInput(sector)
        setDisplayMembers(
            clubMembers.filter((member) => member.business_sector === sector)
        )
    }

    const getSectors = () => {
        const membersSectors = clubMembers.map(member => member.business_sector)
        return [...new Set(membersSectors)]
    }
    const onClubChange = (e) => {
        getClubMembers(e)
        setCurrentClub(clubs.find(club => club.id === e))
    }

    const removeFilters = () => {
        setDisplayMembers(clubMembers);
        setSectorInput('')
    }

    const onTicketCreate = (member) => {
        console.log(currentClub)
        console.log(member)

    }

    if (!currentUser || !currentClub) {return <div>Loading</div>}
    return (
        <Box className="page">

            <div className="page-wrapper">
                <h2 className="page-title">CLUBS</h2>

                <Card sx={{padding: '2rem'}}>
                    <TextField
                        select
                        size="small"
                        label="Sélectionner un club"
                        defaultValue={currentUser.club.id}
                        variant="outlined"
                        sx={{ marginBottom: '2em', width: 'calc(50% - 1em)', marginRight: '1em'}}
                        onChange={(e) => {
                            onClubChange(e.target.value)
                        }}
                    >
                        {clubs.map((club) => (
                            <MenuItem key={club.id} value={club.id}>
                                {club.city}
                            </MenuItem>
                        ))}
                    </TextField>

                    {/*<Typography variant="h6">Infos {}</Typography>*/}
                    <Box variant="h6">
                        {/*<Typography variant="body1">---</Typography>*/}
                        {/*<Typography variant="body1">---</Typography>*/}
                        {/*<Typography variant="body1">*/}
                        {/*</Typography>*/}
                        <a target="_blank" rel="noopener noreferrer" href={currentClub.website}>{currentClub.website}</a>
                    </Box>
                </Card>
                <Card sx={{marginTop: '1rem', padding:'2rem'}}>

                    <TextField
                        select
                        size="small"
                        label="Secteur"
                        defaultValue=''
                        variant="outlined"
                        value={sectorInput}
                        sx={{width: 'calc(50% - 1em)', marginRight: '1em'}}
                        onChange={(e) => {
                            onSectorChange(e.target.value)
                        }}
                    >
                        {getSectors().map((sector) => (
                            <MenuItem key={sector} value={sector}>
                                {sector}
                            </MenuItem>
                        ))}
                    </TextField>
                    {clubMembers !== displayMembers &&
                        <Button onClick={removeFilters}>
                            <CloseIcon/></Button>
                    }

                </Card>
                <Box className="club-members">

                    {displayMembers.map((member) => (
                        <Card key={member.id} className="club-members-card">
                            <div className="card-body">
                                <div className="club-member-card-wrapper">
                                    <div className="club-member-card-row club-member-card-row-status">
                                        <div className="club-member-status">{member.status_display}</div>
                                        <div>
                                            <Tooltip title="Créer un ticket pour ce membre">
                                                <Link to={`/ticket/create?clubId=${currentClub.id}&userId=${member.id}`}>
                                                    <ConfirmationNumberIcon
                                                        sx={{
                                                            color: "#DD2D42",
                                                            border: "1px solid #DDD",
                                                            padding: '.3em',
                                                            borderRadius: '50%'
                                                        }}
                                                    />
                                                </Link>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className="club-member-card-row">
                                        <Avatar
                                            src={member.avatar}
                                            sx={{width: 85, height: 85}}
                                        >
                                            {member.name.split(" ").map((n)=>n[0])}
                                        </Avatar>
                                    </div>
                                    <div className="club-member-card-row">
                                        <Typography variant="h5" sx={{paddingTop: '1rem'}}>{member.name}</Typography>
                                    </div>
                                    <div className="club-member-card-row">
                                        <Typography variant="body2" sx={{paddingTop: '.5rem'}}>{member.business_name}</Typography>
                                    </div>
                                    <div className="club-member-card-row">
                                        <Typography variant="body2"
                                                    sx={{paddingTop: '.5rem', paddingBottom: '1rem'}}>
                                            {member.business_sector}</Typography>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="club-member-card-wrapper">
                                    <div className="club-member-card-link">
                                        <div onClick={() => {
                                            setSelectedUser(member);
                                            setDialogOpen(true);
                                        }}>Voir le profil</div>
                                    </div>
                                </div>

                            </div>
                        </Card>

                    ))}
                </Box>
            </div>

            {
                selectedUser &&
                <UserProfileModal
                    selectedUser={selectedUser}
                    open={dialogOpen}
                    onClose={handleClose}
                />
            }
        </Box>
    );
}


export default Clubs;