import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import axiosInstance from "../../axios";
import {Alert, Box, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";

function PasswordChange(props) {
    const { register, handleSubmit} = useForm();
    const [alertMessage, setAlertMessage] = useState();
    const [alertSeverity, setAlertSeverity] = useState();

    const setAlert = (severity, message) => {
        setAlertSeverity(severity)
        setAlertMessage(message)
    }

    const onSubmit = (data) => {
        axiosInstance
            .patch(`user/password-change/`, data)
            .then((res) => {
                setAlert('success', res.data.message)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    return (
        <div>
                {(alertSeverity && alertMessage) &&
                    <Alert severity={alertSeverity} className="alert">
                        {alertMessage}
                    </Alert>
                }
                <form onSubmit={handleSubmit(onSubmit)} className="">
                    <div className="">
                        <Typography variant="h6" sx={{paddingBottom: '1.5rem'}}>Changer mon mot de passe</Typography>
                        <Typography>Mot de passe actuel</Typography>
                        <TextField
                            {...register('old_password', {required: true})}
                            type="password"
                            size="small"
                            fullWidth
                            className="input"
                        />
                        <Typography sx={{paddingTop: '1rem'}}>Nouveau mot de passe</Typography>
                        <TextField
                            {...register('new_password_1', {required: true})}
                            type="password"
                            size="small"
                            fullWidth
                            className="input"
                        />
                        <Typography sx={{paddingTop: '1rem'}}>Confirmation</Typography>
                        <TextField
                            {...register('new_password_2', {required: true})}
                            type="password"
                            size="small"
                            fullWidth
                            className="input"
                        />
                        <Box sx={{textAlign: 'right', paddingTop: '1rem'}}>
                            <Button variant="contained" type="submit">
                                Envoyer
                            </Button>
                        </Box>
                    </div>
                </form>
        </div>
    );
}

export default PasswordChange;