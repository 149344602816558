import React from 'react';
import {
    Box,
    Button,
    Dialog,
} from "@mui/material";
import UserAvatar from "./UserAvatar";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';


function UserProfileModal(props) {
    const { onClose, selectedUser, open } = props;

    const handleClose = () => {
        onClose(selectedUser);
    };

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            className="admin-modal"
            PaperProps={{ className: "admin-modal-paper-props" }}
            sx={{maxWidth: '500px', margin: '4rem auto 0 auto'}}
        >

            <Box sx={{padding: "2em", textAlign: 'center'}}>
                <Box sx={{display: 'flex', width: '100%', alignContent: 'center', justifyContent: 'center'}}>

                    <UserAvatar height={60} width={60} imageRelPath={selectedUser.business_logo}/>
                    <h4 className="profile-modal-row profile-modal-username">{selectedUser.business_name}</h4>
                </Box>
                <div className="profile-modal-row">{selectedUser.business_address}</div>
                <div className="profile-modal-row profile-modal-description">{selectedUser.business_description}</div>
                <Box sx={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                    <a href={`tel:${selectedUser.phone_number}`}>
                        <PhoneAndroidIcon
                            sx={{color: '#fff', backgroundColor: '#DD2D42', padding: '1rem', margin: '1rem', borderRadius: '50%', fontSize: '2rem'}}
                        />
                    </a>

                    <a href={`mailto:${selectedUser.email}`}>
                        <MailOutlineIcon
                            sx={{color: '#fff', backgroundColor: '#DD2D42', padding: '1rem', margin: '1rem', borderRadius: '50%', fontSize: '2rem'}}
                        />
                    </a>
                </Box>

            </Box>
            <Button variant="text" onClick={onClose}>Fermer</Button>
        </Dialog>
    );
}

export default UserProfileModal;