import React from 'react';
import {Box, CircularProgress} from "@mui/material";

function Spinner(props) {
    return (
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', paddingTop: '4rem'}}>
            <CircularProgress color="secondary"/>
        </Box>
    );
}

export default Spinner;