import React, {useState} from 'react';
import {Alert, Button, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useForm} from "react-hook-form";
import axiosInstance from "../axios";

function PageForgotPassword(props) {
    const { register, handleSubmit} = useForm();
    const [alertMessage, setAlertMessage] = useState();
    const [alertSeverity, setAlertSeverity] = useState();

    const onSubmit = (data) => {
        axiosInstance
            .post('forgot-password/', data)
            .then((res) => {
                setAlertSeverity('success')
                setAlertMessage(res.data.message)
                console.log(res)
            })
            .catch((err) => {
                setAlertSeverity('error')
                setAlertMessage(err.response.data.message)
            })
    }

    return (
        <div className="page">
            <div style={{margin: '5rem auto 0 auto', maxWidth: '400px'}}>
                <div style={{width: "100%", textAlign: 'center'}}>
                    <Typography variant="h5">
                        Demande de réinitialisation du mot de passe
                    </Typography>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '2rem'}}>
                        <TextField
                            {...register('email', {required: true})}
                            fullWidth
                            size="small"
                            type="email"
                            defaultValue=''
                            label='Email'
                        />
                    </div>

                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '2rem'}}>
                        <Button type="submit" variant="contained" className="login-form-button">
                            Envoyer
                        </Button>
                    </div>
                </form>

                {(alertSeverity && alertMessage) &&
                    <Alert severity={alertSeverity} className="alert">
                        {alertMessage}
                    </Alert>
                }
            </div>

        </div>
    );
}

export default PageForgotPassword;