import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


function TurnoverLineChart(props) {
    const { data } = props;
    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="created_date"/>
                <YAxis />
                <Tooltip />
                <Legend />
                <Line dataKey="turnover" stroke="#8884d8" name="Chiffre d'affaires"/>
            </LineChart>
        </ResponsiveContainer>
    );
}

export default TurnoverLineChart;