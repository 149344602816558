import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {TextField, Button, Box} from "@mui/material";
import axiosInstance from "../axios";
import {Alert} from "@mui/material";


function AdminUserInvitation(props) {
    const {onInvitationSent} = props;

    const {
        register,
        handleSubmit,
    } = useForm();

    const [alert, setAlert] = useState();

    const onSubmit = (data) => {
        setAlert(null)
        axiosInstance
            .post(`invitation/`, data)
            .then((res) => {
                setAlert(res.status)
                onInvitationSent(res.data)
            })
            .catch((err) => {
                setAlert(400)
            })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{display: 'flex', padding: '1rem'}}>

                <TextField
                    label="Email de l'invité"
                    size="small"
                    type="email"
                    {...register('email', {required: true})}
                    sx={{marginRight: '2em', flexGrow: 1}}>

                </TextField>
                <Button
                    // fullWidth
                    type="submit"
                    variant="contained"
                    size="small"
                    color="secondary"
                    sx={{
                        display: 'flex',
                        height: '1.5em',
                        padding: '1.5em 2.5em'
                    }}
                    onClick={handleSubmit(onSubmit)}
                >
                    Inviter
                </Button>
            </Box>
            {alert &&
                <Box p={2}>

                    <Alert severity={alert === 201 ? 'success' : 'error'}>
                        {alert === 201
                            ? "L'invitation a été envoyée"
                            : 'Une erreur est survenue'}
                    </Alert>
                </Box>
            }
        </form>
    );
}

export default AdminUserInvitation;
