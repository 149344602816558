import React, {useEffect, useState} from 'react';
import './LatestTickets.css'
import {Box, Button, Card, AvatarGroup} from "@mui/material";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {Link} from "react-router-dom";
import UserAvatar from "../../UserAvatar";
import TicketRatingStars from "../TicketRatingStars";
import axiosInstance from "../../../axios";
import Spinner from "../../Spinner";
import TicketRating from "../TicketRating/TicketRating";


/**
 * Display the 3 latest tickets created by or assigned to current user as cards.
 * @param props
 * @returns {JSX.Element}
 */

function LatestTickets(props) {
    const [tickets, setTickets] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    const latest = 3;

    /**
     * Retrieve the latest tickets
     */
    useEffect(() => {
        setIsLoading(true);
        axiosInstance
            .get(`ticket/?latest=${latest}`)
            .then((res) => {
                setTickets(res.data);
                setIsLoading(false);
                console.log(res.data)
            })

    }, []);

    const hasTickets = () => {
        return tickets && tickets.length
    }

    if (isLoading) { return <Spinner /> }
    return (
        <div className="section-container">
            <div className="section-title-container">
                <h4>Tickets récents</h4>

                {hasTickets()
                    ?
                    <Button variant="text" className="section-title-button">
                        <Link to='/ticket'>Voir tout</Link>
                    </Button>
                    :
                    <></>
                }
            </div>

            <Box className="section-content">
                {tickets.map((ticket) => (
                    <Card className="latest-ticket-card" key={ticket.id} sx={{width: '31%'}}>
                        <div className="latest-ticket-column-wrapper">
                            <div className="latest-ticket-header">
                                <AvatarGroup>
                                    <UserAvatar imageRelPath={ticket.creator.avatar}/>
                                    <UserAvatar imageRelPath={ticket.assignee.avatar}/>
                                </AvatarGroup>
                                <div style={{display: "flex"}}>
                                    <div style={{marginRight: '1em'}}>
                                        <TicketRating size="medium" ticket={ticket}  editable={false}/>
                                    </div>
                                    {
                                        !ticket.closed
                                            ? <LockOpenIcon className="latest-ticket-unlock-icon"/>
                                            : <LockOutlinedIcon className="latest-ticket-lock-icon"/>
                                    }

                                </div>
                            </div>
                            <div className="latest-ticket-column-wrapper">
                                <div className="latest-ticket-body">
                                    <div className="latest-ticket-business">{ticket.client_business_name}</div>
                                    <div className="latest-ticket-client">{ticket.client_name}</div>
                                    <div className="latest-ticket-title">{ticket.title}</div>

                                    <div className="latest-ticket-footer">

                                        <Link to={`ticket/${ticket.id}`}>
                                            <Button variant="outlined" className="latest-ticket-button">Voir</Button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                ))}
            </Box>
        </div>
    );
}


export default LatestTickets;