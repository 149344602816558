import React from 'react';
import MyTickets from "../components/Ticket/MyTickets/MyTickets";


function PageMyTickets(props) {
    return (
        <div className="page">

            <MyTickets />
        </div>
    );
}


export default PageMyTickets;