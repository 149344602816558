import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { DataGrid } from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {useNavigate} from "react-router-dom";

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {
    return (
        <Box className="ticket-list-search">

            <TextField
                variant="standard"
                value={props.value}
                onChange={props.onChange}
                placeholder="Search…"
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" className="ticket-list-search-icon"/>,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
            />
        </Box>
    );
}


export default function TicketList(props) {
    const { dataRows, dataColumns } = props;
    const navigate = useNavigate();

    const displayTicket = (rowProps) => {
        const ticketId = rowProps.getValue(rowProps.id, 'id');
        navigate(`/ticket/${ticketId}`);

    }

    const gridData = {
        rows: dataRows,
        columns: dataColumns
    }

    const [searchText, setSearchText] = React.useState('');
    const [rows, setRows] = React.useState(gridData.rows);

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = gridData.rows.filter((row) => {
            return Object.keys(row).some((field) => {
                if (row[field]) {

                    return searchRegex.test(row[field].toString());
                }

                // }
            });
        });
        setRows(filteredRows);
    };

    React.useEffect(() => {
        setRows(gridData.rows);
    }, [gridData.rows]);

    return (
            <DataGrid
                components={{ Toolbar: QuickSearchToolbar }}
                rows={rows}
                columns={gridData.columns}
                autoHeight
                pageSize={10}
                onRowClick={displayTicket}
                componentsProps={{
                    toolbar: {
                        value: searchText,
                        onChange: (event) => requestSearch(event.target.value),
                        clearSearch: () => requestSearch(''),
                    },
                }}
                className=""
            />
    );
}